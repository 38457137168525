<!-- Data -->
<div class="cursor-pointer" (click)="visible = !visible">
  <div
    [style.backgroundColor]="color?.primaryColor"
    class="w-5 h-5 rounded-full"
    [ngClass]="color?.primaryColor && color?.primaryColor !== '#FFFFFF' ? '' : 'border-2 border-gray-200'"></div>
  <!-- Overlay -->
  <p-overlay [(visible)]="visible">
    <div class="bg-white p-2 rounded-sm mt-1 border shadow-sm grid grid-cols-9 gap-2 w-max">
      @for (color of Colors; track color.value; let i = $index) {
        <div
          class="transition group h-6 w-6 flex items-center justify-center rounded-full cursor-pointer hover:ring-2 hover:ring-offset-1 hover:ring-neutral-alpha-200A"
          [style.backgroundColor]="color.primaryColor"
          (click)="onSelectColor(color.primaryColor)">
          @if (color.primaryColor === value) {
            <img src="assets/images/icons/check-icon{{ color.primaryColor === '#FFFFFF' ? '-blue' : '' }}.svg" />
          }
        </div>
      }
    </div>
  </p-overlay>
</div>
