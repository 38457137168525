<div class="w-full flex justify-center items-center relative transition-all duration-150 ease-in-out delay-150">
  <div class="w-fit fixed bottom-4 flex gap-2">
    <!--  <div class="bg-white flex items-center shadow-md rounded-md w-fit p-1.5">
      <div class="px-2 py-0.5 hover:bg-neutral-200 rounded-sm cursor-pointer" (click)="undoAction()">
        <i class="icon-wl-undo text-6 text-neutral-900"></i>
      </div>


      <div class="px-2 py-0.5 hover:bg-neutral-200 rounded-sm cursor-pointer" (click)="redoAction()">
        <i class="icon-wl-redo text-6 text-neutral-900"></i>
      </div>
    </div> -->

    <div class="bg-white flex items-center shadow-md rounded-md p-1.5">
      <!-- Fit to Screen -->
      <div class="p-2 hover:bg-neutral-200 rounded-sm cursor-pointer" (click)="fitToScreen()">
        <img src="assets/images/icons/fix-width.svg" alt="fix" />
      </div>

      <span class="text-neutral-300 px-3">|</span>

      <!-- Zoom Out -->
      <div class="px-2 py-0.5 hover:bg-neutral-200 rounded-sm cursor-pointer" (click)="zoomOut()">
        <i class="icon-wl-minus text-6"></i>
      </div>

      <span class="font-medium min-w-5">{{ zoomLevel }}%</span>

      <!-- Zoom In -->
      <div class="px-2 py-0.5 hover:bg-neutral-200 rounded-sm cursor-pointer" (click)="zoomIn()">
        <i class="icon-wl-plus text-6"></i>
      </div>
    </div>
  </div>
</div>
