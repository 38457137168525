<div class="relative flex pt-13">
  <app-header></app-header>
  <!-- CONTENT -->
  <app-sidebar></app-sidebar>
  <div style="contain: inline-size" class="flex-1 bg-gray-50">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  class="fixed w-fit pb-2 rounded-tr-md shadow-lg bottom-0 border-none text-1 font-medium text-neutral-500 left-20 z-10 flex">
  {{ appVersion }}
</div>
