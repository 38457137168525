import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
  standalone: true
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const now_date = new Date();
    const date = new Date(value);

    if (
      now_date.getFullYear() === date.getFullYear() &&
      now_date.getMonth() === date.getMonth() &&
      now_date.getDate() === date.getDate()
    ) {
      return 'TODAY';
    }

    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: '2-digit'
    };

    return date.toLocaleDateString('en-US', options).toUpperCase();
  }
}
