import { Permission } from '@core/models/interfaces/permission/permission';

export function flattenPermissionCodes(data: Permission[]) {
  let result: string[] = [];

  function extractCodes(items: Permission[]) {
    items.forEach(item => {
      if (item.code && !result.includes(item.code)) {
        result.push(item.code);
      }
      if (item.permissions && item.permissions.length > 0) {
        extractCodes(item.permissions);
      }
    });
  }
  extractCodes(data);

  return result;
}
