import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectFacebookAccounts, selectFacebookAccountSelected } from '@state/auth/auth.selectors';
import { setFacebookAccounts, setFacebookAccountSelected } from '@state/auth/auth.actions';
import { FacebookAccount } from '@core/models/interfaces/facebook/facebook-account';
import { Subject, takeUntil } from 'rxjs';
import { FacebookAdsAdminService } from '@core/services/facebook.service.ts/facebook-ads-admin.service';
import { IntegrationItem } from '@core/models/interfaces/facebook/integration';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SkeletonModule } from 'primeng/skeleton';
import { FBLoginResponse } from '@core/models/interfaces/auth';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from 'src/environments/environment';

declare let FB: any;

@Component({
  selector: 'app-integration',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TableModule,
    RadioButtonModule,
    FormsModule,
    ConfirmDialogModule,
    EmptyTableComponent,
    SkeletonModule,
    ProgressSpinnerModule
  ],
  templateUrl: './integration.component.html',
  styleUrl: './integration.component.scss',
  providers: [ConfirmationService]
})
export class IntegrationComponent implements OnInit, OnDestroy {
  readonly unsubscribe$ = new Subject<void>();

  isLoading: boolean = false;
  isConnecting: boolean = false;
  accountSelected: FacebookAccount;
  accountIdSelected: string = '';
  accounts: FacebookAccount[] = [];

  fanPages: IntegrationItem[] = [];
  businessPortfolio: IntegrationItem[] = [];
  adAccounts: IntegrationItem[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private store: Store<AppState>,
    private facebookAdsAdminService: FacebookAdsAdminService,
    private messageService: MessageService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.getAccounts();
    this.subscribeAccountSelected();
  }

  getAccounts(): void {
    this.store
      .select(selectFacebookAccounts)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(accounts => {
        this.accounts = accounts;
      });
  }

  subscribeAccountSelected(): void {
    this.store
      .select(selectFacebookAccountSelected)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(account => {
        if (account) {
          this.accountSelected = account;
          this.accountIdSelected = account.id;
          this.fetchData();
        }
      });
  }

  fetchData() {
    this.isLoading = true;
    this.facebookAdsAdminService.getIntegrationsData(this.accountSelected.accessToken).subscribe({
      next: data => {
        if (data.adAccounts) {
          this.adAccounts = data.adAccounts;
        }
        if (data.businesses) {
          this.businessPortfolio = data.businesses;
        }
        if (data.pages) {
          this.fanPages = data.pages;
        }
        this.isLoading = false;
      }
    });
  }

  onSelectAccount(facebookAccount: FacebookAccount) {
    this.store.dispatch(setFacebookAccountSelected({ facebookAccount }));
    localStorage.setItem('fbAccount', facebookAccount.id);
  }

  onConnect() {
    FB.login(
      (response: FBLoginResponse) => {
        if (response.status !== 'connected') {
          return;
        }

        this.ngZone.run(() => {
          this.isConnecting = true;
          this.facebookAdsAdminService.authentication(response.authResponse.accessToken).subscribe({
            next: account => {
              const uniqueArray: FacebookAccount[] = [...this.accounts, account].reduce(
                (acc: FacebookAccount[], obj) => {
                  if (!acc.some(item => item.id === obj.id)) {
                    acc.push(obj);
                  }
                  return acc;
                },
                []
              );
              this.store.dispatch(
                setFacebookAccounts({
                  facebookAccounts: uniqueArray
                })
              );

              this.isConnecting = false;
            },
            error: () => {
              this.isConnecting = false;
              this.messageService.add({
                severity: 'error',
                detail: 'Failed to connect to Facebook'
              });
            }
          });
        });
      },

      { config_id: environment.CONFIG_ID }
    );
  }

  onDisconnectAccount(event: Event, id: string, name: string) {
    event.stopPropagation();
    this.confirmationService.confirm({
      header: 'Disconnect account',
      message: `Are you sure you want to delete this account ${name}?`,
      rejectLabel: 'Cancel',
      acceptLabel: 'Disconnect',
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.facebookAdsAdminService
          .disconnectAccount(id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              const accounts = this.accounts.filter(account => account.id !== id);
              this.store.dispatch(
                setFacebookAccounts({
                  facebookAccounts: accounts
                })
              );

              this.messageService.add({
                severity: 'success',
                detail: 'Disconnect account successfully'
              });
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: 'An error has occurred. Please try again later'
              });
            }
          });
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
