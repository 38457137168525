import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsSortFieldEnum } from '@core/enum/notification';
import { APP_PERMISSION } from '@core/enum/permisson';
import {
  Group,
  NotificationSetting,
  NotificationSettingUpdate
} from '@core/models/interfaces/notification/notification-setting';
import { PermissionGroup } from '@core/models/interfaces/permission/group';
import { UserPermissionGroup } from '@core/models/interfaces/user';
import { NotificationSettingService } from '@core/services/notification-setting.service';
import { GroupService } from '@core/services/permission/group.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService, TreeNode } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { Subject, takeUntil } from 'rxjs';

interface Product {
  type: string;
  permission: string;
}

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    DialogModule,
    TreeSelectModule,
    FormControlComponent,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonComponent,
    TranslateModule,
    EmptyTableComponent,
    SortTableHeaderComponent,
    NgxPermissionsModule
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  readonly translatePrefix = 'notification-setting.';
  readonly NotificationsSortFieldEnum = NotificationsSortFieldEnum;
  readonly Permissions = APP_PERMISSION;

  visible: boolean = false;
  treeGroupsPermission: TreeNode[];
  groups: PermissionGroup[];
  totalGroup: number = 0;
  isSelectedAllGroup: boolean = false;

  sortOrder: number = 0;
  sortField: string = '';
  loading: boolean = false;
  isLoadingButton: boolean = false;

  settings: NotificationSetting[] = [];
  notificationSettingSelected: NotificationSetting;

  groupForm = this.fb.group({
    permissionGroups: this.fb.control<UserPermissionGroup[]>([])
  });

  constructor(
    private fb: FormBuilder,
    private groupService: GroupService,
    private translateService: TranslateService,
    private notificationSettingService: NotificationSettingService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.fetchGroupData();
    this.fetchNotificationSettings();
  }

  get permissionGroupControl() {
    return this.groupForm.controls['permissionGroups'];
  }

  get groupsPermission() {
    return this.groupForm.get('permissionGroups')?.value;
  }

  fetchGroupData() {
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: groups => {
          this.groups = groups.data.content;
          this.treeGroupsPermission = this.groups.map((group: PermissionGroup) => this.convertToTreeData(group));
          this.onChangeGroups();
          this.permissionGroupControl?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.onChangeGroups();
          });
        }
      });
  }

  fetchNotificationSettings() {
    const sorts = this.sortField ? (this.sortOrder < 0 ? this.sortField : `-${this.sortField}`) : '';
    this.loading = true;
    this.notificationSettingService.getNotificationSettings(0, 10, sorts).subscribe({
      next: settings => {
        this.settings = settings.data.content;
      },
      error: error => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(error.message)
        });
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  onSort(event: { field: string; order: number }) {
    this.sortOrder = event.order;
    this.sortField = event.field;
    this.fetchNotificationSettings();
  }

  onChangeGroups() {
    const currentGroup = this.groupsPermission?.length ? this.groupsPermission.length : 0;
    this.isSelectedAllGroup = currentGroup === this.totalGroup;
  }

  selectedAll() {
    const expandedState = this.getExpandedState(this.treeGroupsPermission);
    if (this.isSelectedAllGroup) {
      this.permissionGroupControl?.setValue(this.getAllNodes(this.treeGroupsPermission));
    } else {
      this.permissionGroupControl?.setValue([]);
    }
    this.restoreExpandedState(expandedState);
  }

  getAllNodes(nodes: TreeNode[]): any {
    let allNodes: TreeNode[] = [];
    nodes.forEach(node => {
      allNodes.push(node);
      if (node.children) {
        if (node.children.length > 0) {
          allNodes.pop();
        }
        allNodes = allNodes.concat(this.getAllNodes(node.children));
      }
    });
    return allNodes;
  }

  getExpandedState(nodes: TreeNode[]): { [key: string]: boolean } {
    const state: { [key: string]: boolean } = {};
    nodes.forEach(node => {
      if (node.key !== undefined && node.key !== null) {
        state[node.key] = node.expanded || false;
      }

      if (node.children) {
        Object.assign(state, this.getExpandedState(node.children));
      }
    });
    return state;
  }

  restoreExpandedState(state: { [key: string]: boolean }) {
    this.setExpandedState(this.treeGroupsPermission, state);
  }

  setExpandedState(nodes: TreeNode[], state: { [key: string]: boolean }) {
    nodes.forEach(node => {
      if (node.key !== undefined) {
        node.expanded = state[node.key] || false;
      }

      if (node.children) {
        this.setExpandedState(node.children, state);
      }
    });
  }

  showDialog(notificationSetting: NotificationSetting) {
    this.notificationSettingSelected = notificationSetting;
    const transformedChildren: { key: string; label: string }[] = [];
    this.notificationSettingSelected.groups.forEach(item => {
      transformedChildren.push({
        key: item.id,
        label: item.name
      });
    });
    this.permissionGroupControl.setValue(transformedChildren);
    this.visible = true;
  }

  onRemove(event: Event, id?: string, type?: string) {
    event.stopPropagation();

    if (this.groupsPermission && id) {
      const newValue = this.groupsPermission.filter(item => item.key !== id);
      this.groupForm.get('permissionGroups')?.patchValue(newValue);
    }
  }

  convertToTreeData(data: PermissionGroup) {
    const treeNode: TreeNode = {
      key: data.id,
      label: data.name,
      children: [],
      partialSelected: false,
      selectable: true,
      expanded: false
    };
    if (data.subGroups && data.subGroups.length > 0) {
      treeNode.children = data.subGroups.map(group => this.convertToTreeData(group));
      treeNode.selectable = false;
    } else {
      this.totalGroup += 1;
    }
    return treeNode;
  }

  onSave() {
    const groups = this.getAllIds(this.groupsPermission);
    const dto: NotificationSettingUpdate = {
      name: this.notificationSettingSelected.name,
      groupIds: groups
    };
    this.isLoadingButton = true;
    this.notificationSettingService.updateNotificationSetting(this.notificationSettingSelected.id, dto).subscribe({
      next: () => {
        this.visible = false;
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(this.translatePrefix + 'update-success')
        });
        this.fetchNotificationSettings();
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.translatePrefix + 'update-failed')
        });
      },
      complete: () => {
        this.isLoadingButton = false;
      }
    });
  }

  getAllIds(groups: any) {
    return groups.map((item: any) => item.key);
  }

  getFormattedGroupNames(setting: NotificationSetting): string {
    return setting.groups.map((group: Group) => group.name).join(', ');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
