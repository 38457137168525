import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { APP_PERMISSION } from '@core/enum/permisson';
import { BannerDetail } from '@core/models/interfaces/admin-content/admin-content';
import { BannerService } from '@core/services/amin-content/banner.service';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AdminContentBannerEditComponent } from '../admin-content-banner-edit/admin-content-banner-edit.component';
import { AdminContentSortFieldEnum } from '@core/enum/admin-content';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';

@Component({
  selector: 'app-admin-content-banner',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    CustomAvatarComponent,
    TooltipModule,
    CustomPaginatorComponent,
    AdminContentBannerEditComponent,
    CustomAvatarComponent,
    CustomImageComponent,
    EmptyTableComponent,
    SortTableHeaderComponent,
    NgxPermissionsModule
  ],
  templateUrl: './admin-content-banner.component.html',
  styleUrl: './admin-content-banner.component.scss'
})
export class AdminContentBannerComponent {
  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();
  readonly Permissions = APP_PERMISSION;

  readonly AdminContentSortFieldEnum = AdminContentSortFieldEnum;
  sortField: string = '';
  sortOrder: number = 0;
  isVisibleEditBanner: boolean = false;

  pagination = {
    page: 0,
    size: 10
  };

  bannerState: {
    banners: BannerDetail[];
    loading: boolean;
    keyword: string;
    totalBanner: number;
    bannerCurrent?: BannerDetail;
  } = {
    banners: [],
    loading: false,
    keyword: '',
    totalBanner: 0,
    bannerCurrent: undefined
  };

  constructor(private bannerService: BannerService) {}

  ngOnInit(): void {
    this.fetchBanners();
  }

  onEditBanner(banner: BannerDetail) {
    this.isVisibleEditBanner = true;
    this.bannerState.bannerCurrent = banner;
  }

  fetchBanners() {
    const sorts = this.sortField ? (this.sortOrder < 0 ? this.sortField : `-${this.sortField}`) : '';
    this.bannerState.loading = true;
    this.bannerService
      .getBannerManagement({ page: this.pagination.page, size: this.pagination.size, sorts: sorts })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.bannerState = {
            ...this.bannerState,
            loading: false,
            banners: res?.data?.content,
            totalBanner: res?.data?.total
          };
        }
      });
  }

  onSort(event: { field: string; order: number }) {
    this.sortOrder = event.order;
    this.sortField = event.field;
    this.fetchBanners();
  }

  onUpdateSuccess() {
    this.pagination.page = 0;
    this.fetchBanners();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
