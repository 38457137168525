import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UNIT_NUMBER } from '@core/enum/dashboard';
import { TranslateModule } from '@ngx-translate/core';
import { ShortNumberPipe } from '@shared/pipes/short-number.pipe';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-statistic',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipModule, ShortNumberPipe],
  templateUrl: './statistic.component.html',
  styleUrl: './statistic.component.scss'
})
export class StatisticComponent {
  @Input() isBorder: boolean = true;
  @Input() title: string = '';
  @Input() amount: number = 0;
  @Input() isAll: boolean = false;
  @Input() dataType: UNIT_NUMBER = UNIT_NUMBER.NUMBER;
  @Input() rate?: number = 0;

  readonly UNIT_NUMBER = UNIT_NUMBER;

  getAbsInfluence(value?: number) {
    if (!value) return '';
    return Math.abs(value);
  }
}
