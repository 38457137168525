import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayModule } from 'primeng/overlay';
import { delay, Subject, takeUntil } from 'rxjs';

import { Colors } from '@core/constants/common';
import { FieldTypeOptions } from '@core/constants/data-configuration';
import { ColorEnum } from '@core/enum/common';
import { FieldTypeEnum } from '@core/enum/data-configuration';
import { IdName, ModeAction } from '@core/models/interfaces/common';
import { Company } from '@core/models/interfaces/company';
import {
  DynamicFieldCreate,
  DynamicFieldCreateOption,
  DynamicFieldDetails
} from '@core/models/interfaces/data-config/dynamic-field';
import { CompanyService } from '@core/services/company.service';
import { DynamicFieldService } from '@core/services/data-config/dynamic-field.service';
import { ProductTypeService } from '@core/services/product-type.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog/custom-dialog.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { ColorPickerComponent } from '../color-picker/color-picker.component';

@Component({
  selector: 'app-field-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    OrderListModule,
    DragDropModule,
    OverlayModule,
    InputSwitchModule,
    TranslateModule,

    ColorPickerComponent,
    CustomDialogComponent,
    ButtonComponent,
    FormControlComponent,
    CustomLoadingComponent
  ],
  templateUrl: './field-edit.component.html',
  styleUrl: './field-edit.component.scss'
})
export class FieldEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() module: string = '';
  @Input() mode: ModeAction = 'CREATE';
  @Input() selectedId: string = '';
  @Input() visible: boolean = false;

  @Output() visibleChange = new EventEmitter();
  @Output() refreshList = new EventEmitter();

  readonly translatePrefix = 'data-configuration.';
  readonly messagePrefix = 'data-configuration.message.';
  readonly FieldTypeOptions = FieldTypeOptions;
  readonly Colors = Colors;
  readonly FieldTypeEnum = FieldTypeEnum;
  readonly unsubscribe$ = new Subject();

  fieldForm: FormGroup;
  fieldData: DynamicFieldDetails | null = null;
  isLoading: boolean = false;
  isEditing: boolean = false;

  companies: Company[] = [];
  productTypes: IdName[] = [];

  constructor(
    private fb: FormBuilder,
    private dynamicFieldService: DynamicFieldService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private productTypeService: ProductTypeService
  ) {}

  ngOnInit(): void {
    this.loadCompanies();
    this.loadProductTypes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedId = changes?.['selectedId']?.currentValue;

    if (selectedId) {
      // EDIT FIELD (HAVE CHANGE ID)
      this.fetchFieldDetails(selectedId);
    } else if (selectedId !== undefined) {
      // CREATE FIELD
      this.initFieldForm();
      this.fieldData = null;
    } else {
      // EDIT FIELD (NO CHANGE ID)
      if (this.fieldData) {
        this.convertDataToForm(this.fieldData);
      }
    }
  }

  get FieldTypeOptionsData() {
    return FieldTypeOptions.filter(option => option.type !== FieldTypeEnum.DEPENDENT_DROPDOWN);
  }

  fetchFieldDetails(fieldId: string) {
    this.isLoading = true;
    this.dynamicFieldService
      .getDynamicFieldById(fieldId)
      .pipe(takeUntil(this.unsubscribe$), delay(100))
      .subscribe({
        next: res => {
          this.fieldData = res.data;
          this.convertDataToForm(res.data);

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  convertDataToForm(data: DynamicFieldDetails) {
    this.fieldForm = this.fb.group({
      name: [{ value: data.name, disabled: data.dynamic === false }, [Validators.required]],
      type: [{ value: data.fieldType, disabled: true }, [Validators.required]],
      parentId: this.fb.control(null),
      tagColor: [{ value: data.tagColor, disabled: true }],
      required: [{ value: data.required, disabled: true }],
      options: this.fb.array([])
    });

    if (data.options && data.code !== 'PRODUCT_TYPE' && data.code !== 'PRODUCT_NAME') {
      for (let option of data.options) {
        const children: FormArray<any> = this.fb.array<any>([]);

        if (option?.['children']) {
          for (let child of option.children.sort((a, b) => a.position - b.position)) {
            children.push(
              this.fb.group({
                id: [child.id],
                name: [child.name, Validators.required]
              })
            );
          }
        }

        const fieldOption = this.fb.group({
          id: [option.id],
          name: [option.name, Validators.required],
          color: [option.color],
          children: children
        });

        this.fieldOptionFormArray.push(fieldOption);
      }
    }
  }

  onChangeParentField(event: DropdownChangeEvent) {
    const fieldId = this.fieldData?.id;
    if (!fieldId) return;
    this.dynamicFieldService.getDynamicFieldById(fieldId, event.value).subscribe({
      next: res => {
        const options = res.data.options;
        this.fieldOptionFormArray.clear();
        if (options?.length) {
          for (let option of options) {
            this.fieldOptionFormArray.push(
              this.fb.group({
                id: [option.id],
                name: [option.name, Validators.required],
                color: [option.color]
              })
            );
          }
        }
      }
    });
  }

  get fieldNameControl() {
    return this.fieldForm.controls?.['name'] as FormControl;
  }

  get parentIdControl() {
    return this.fieldForm.controls?.['parentId'] as FormControl;
  }

  get fieldTypeControl() {
    return this.fieldForm.controls?.['type'] as FormControl;
  }

  get tagColorControl() {
    return this.fieldForm.controls?.['tagColor'] as FormControl;
  }

  get fieldOptionFormArray() {
    return this.fieldForm.controls?.['options'] as FormArray;
  }

  fieldOptionColor(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls?.['color'];
  }

  fieldOptionName(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls?.['name'] as FormControl;
  }

  fieldOptionChildren(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls['children'] as FormArray;
  }

  fieldOptionChild(index: number, childIndex: number) {
    return (this.fieldOptionChildren(index).at(childIndex) as FormGroup).controls?.['name'] as FormControl;
  }

  initFieldForm() {
    this.fieldForm = this.fb.group({
      name: this.fb.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      parentId: this.fb.control(null),
      tagColor: this.fb.control(false),
      required: this.fb.control(false)
    });
  }

  onVisibleChange($event: boolean) {
    this.visible = $event;
    this.visibleChange.emit(this.visible);
  }

  onDropOption(event: CdkDragDrop<string[]>) {
    const formArray = this.fieldOptionFormArray;

    moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
  }

  onDropChildOption(event: CdkDragDrop<string[]>, index: number) {
    const array = this.fieldOptionChildren(index).value;

    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.fieldOptionChildren(index).setValue(array);
  }

  onChangeFieldType(event: DropdownChangeEvent) {
    const fieldType = event.value;
    this.fieldForm.removeControl('options');

    switch (fieldType) {
      case FieldTypeEnum.SELECT: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required]),
              color: this.fb.control(ColorEnum.BLUE)
            })
          ])
        );
        break;
      }
      case FieldTypeEnum.MULTI_SELECT: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required])
            })
          ])
        );
        break;
      }
      case FieldTypeEnum.DEPENDENT_DROPDOWN: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required]),
              color: this.fb.control(ColorEnum.BLUE),
              children: this.fb.array([
                this.fb.group({
                  name: this.fb.control('', [Validators.required])
                })
              ])
            })
          ])
        );
      }
    }
  }

  onAddFieldOption() {
    const fieldType = this.fieldForm.getRawValue().type;

    switch (fieldType) {
      case FieldTypeEnum.SELECT: {
        this.fieldOptionFormArray.push(
          this.fb.group({
            name: this.fb.control('', [Validators.required]),
            color: this.fb.control(ColorEnum.BLUE)
          })
        );
        break;
      }
      case FieldTypeEnum.MULTI_SELECT: {
        this.fieldOptionFormArray.push(this.fb.group({ name: this.fb.control('', [Validators.required]) }));
        break;
      }
      case FieldTypeEnum.DEPENDENT_DROPDOWN: {
        this.fieldOptionFormArray.push(
          this.fb.group({
            name: this.fb.control('', [Validators.required]),
            color: this.fb.control(ColorEnum.BLUE),
            children: this.fb.array([
              this.fb.group({
                name: this.fb.control('', [Validators.required])
              })
            ])
          })
        );
        break;
      }
    }
  }

  onAddFieldChildOption(index: number) {
    this.fieldOptionChildren(index).push(
      this.fb.group({
        name: this.fb.control('', [Validators.required])
      })
    );
  }

  onDeleteFieldOption(index: number) {
    this.fieldOptionFormArray.removeAt(index);
  }

  onDeleteFieldChildOption(index: number, optionIndex: number) {
    this.fieldOptionChildren(index).removeAt(optionIndex);
  }

  onChangeOptionColor(event: string, index: number) {
    this.fieldOptionColor(index).setValue(event);
  }

  onCancel() {
    this.onVisibleChange(false);
  }

  onSave() {
    this.fieldForm.markAllAsTouched();
    if (this.fieldForm.invalid) return;

    const value = this.fieldForm.getRawValue();
    const parentId = value.parentId;

    const body: DynamicFieldCreate = {
      name: value.name,
      fieldType: value.type,
      required: value.required,
      tagColor: value.tagColor,
      module: this.module,
      optionsRequest: value.options?.map((option: DynamicFieldCreateOption, index: number) => {
        const color = Colors.find(item => item.primaryColor === option.color);

        return {
          name: option.name,
          color: color?.primaryColor,
          backgroundColor: color?.bgColor,
          id: option?.id,
          children: option.children?.map((item, index) => ({ ...item, position: index })),
          position: index,
          parentId: parentId
        };
      })
    };

    const source =
      this.mode === 'CREATE'
        ? this.dynamicFieldService.createDynamicField(body)
        : this.dynamicFieldService.updateDynamicFieldById(this.selectedId, body, parentId);

    this.isEditing = true;
    source.subscribe({
      next: res => {
        this.isEditing = false;

        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(
            this.messagePrefix + (this.mode === 'CREATE' ? 'create-field-success' : 'update-field-success')
          )
        });

        this.fieldData = res.data;
        if (this.fieldData.code === 'COMPANY') {
          this.loadCompanies();
          this.loadProductTypes();
        } else if (this.fieldData.code === 'PRODUCT_TYPE') {
          this.loadProductTypes();
        }
        if (this.mode === 'CREATE') {
          this.fieldForm.reset();
          this.fieldData = null;
        }

        this.onVisibleChange(false);
        this.refreshList.emit();
      },
      error: () => {
        this.isEditing = false;

        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(
            this.messagePrefix + (this.mode === 'CREATE' ? 'create-field-failed' : 'update-field-failed')
          )
        });
      }
    });
  }

  loadCompanies() {
    this.companyService
      .getCompanies({ size: 50 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.companies = res.data.content;
        }
      });
  }

  loadProductTypes() {
    this.productTypeService
      .getProductTypes({ size: 50 })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.productTypes = res.data.content;
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
