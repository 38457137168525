<p-sidebar
  [(visible)]="visible"
  [showCloseIcon]="false"
  position="right"
  styleClass="w-[480px]"
  (onHide)="visibleChange.emit(false)">
  <ng-template pTemplate="header">
    <div class="flex flex-row items-center flex-1 justify-between">
      <div
        class="flex items-center justify-center cursor-pointer hover:bg-neutral-200 hover:text-blue-600 rounded-sm px-1.5">
        <i class="icon-wl-chevrons-right text-4" (click)="visibleChange.emit(false)"></i>
      </div>
    </div>
  </ng-template>

  <!-- BODY -->
  <div class="flex flex-col">
    <!-- IMAGE BANNER -->
    <img src="assets/images/backgroundUser.jpg" alt="user" />

    <!-- INFO -->
    <div class="px-5 flex gap-4 -mt-5">
      <app-custom-avatar
        class="ring-2 ring-white rounded-full"
        size="2xl"
        [imgId]="nodeDetail?.avatar ?? ''"
        [name]="nodeDetail?.fullName ?? ''"></app-custom-avatar>

      <div class="flex flex-col justify-end">
        <div class="flex gap-2 items-center">
          <span class="text-lg font-semibold">{{ nodeDetail?.fullName ?? '--' }}</span>
          <div
            class="flex justify-center px-2 items-center rounded-sm text-2 font-medium min-h-5 w-fit"
            [ngStyle]="{
              'background-color': nodeDetail?.status?.backgroundColor ?? '',
              color: nodeDetail?.status?.color
            }">
            <span>{{ nodeDetail?.status?.name ?? '' }} </span>
          </div>
        </div>
        <div class="flex items-center gap-1.5 text-neutral-600">
          <span>{{ nodeDetail?.userId }}</span>
          <div class="w-1 h-1 rounded-full bg-neutral-600"></div>
          <span class="">{{ nodeDetail?.email ?? '--' }}</span>
        </div>
      </div>
    </div>

    <!-- GENERAL INFORMATION -->
    <div class="px-5 py-4 flex flex-col gap-3">
      <span class="text-2 font-semibold">{{
        translateDetailPrefix + 'general-information' | translate | uppercase
      }}</span>
      <div class="flex gap-3 items-center">
        <!-- Name -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'name' | translate }}</span>
          <span>{{ nodeDetail?.fullName ?? '--' }}</span>
        </div>

        <!-- Other name -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'other-name' | translate }}</span>
          <span>{{ nodeDetail?.otherName && nodeDetail?.otherName?.length ? nodeDetail?.otherName : '--' }}</span>
        </div>
      </div>

      <div class="flex gap-3 items-center">
        <!-- DOB -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'dob' | translate }}</span>
          <span>{{ (nodeDetail?.dateOfBirth | date: 'MM/dd/yyyy') ?? '--' }}</span>
        </div>

        <!-- Gender -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'gender' | translate }}</span>
          <span>{{ (nodeDetail?.gender | lowercase | titlecase) ?? '--' }}</span>
        </div>
      </div>
    </div>

    <!-- CONTACT INFORMATION -->
    <div class="px-5 py-4 flex flex-col gap-3">
      <span class="text-2 font-semibold">{{
        translateDetailPrefix + 'contact-information' | translate | uppercase
      }}</span>
      <div class="flex gap-3 items-center">
        <!-- Work phone -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'work-phone' | translate }}</span>
          <div class="flex gap-1 items-center">
            <span>{{ nodeDetail?.phone ?? '--' }}</span>
            @if (nodeDetail?.phone) {
              <a class="flex" [href]="'tel:' + (nodeDetail?.phone ?? '')">
                <i class="icon-wl-phone-call text-neutral-600 cursor-pointer text-4 hover:text-blue-600"></i>
              </a>
            }
          </div>
        </div>

        <!-- Email -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'email' | translate }}</span>
          <div class="flex gap-1 items-center">
            <span>{{ nodeDetail?.email ?? '--' }}</span>
            @if (nodeDetail?.email) {
              <a class="flex" href="mailto: {{ nodeDetail?.email }}">
                <i class="icon-wl-mail cursor-pointer text-neutral-600 text-4 hover:text-blue-600"></i>
              </a>
            }
          </div>
        </div>
      </div>
    </div>

    <!-- WORKING INFORMATION -->
    <div class="px-5 py-4 flex flex-col gap-3">
      <span class="text-2 font-semibold">{{
        translateDetailPrefix + 'working-information' | translate | uppercase
      }}</span>
      <div class="flex gap-3 items-center">
        <!-- Start date -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'start-date' | translate }}</span>
          <span>{{ (nodeDetail?.startDate | date: 'MM/dd/yyyy') ?? '--' }}</span>
        </div>

        <!-- Location -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'location' | translate }}</span>
          <span>{{ nodeDetail?.location?.name ?? '--' }}</span>
        </div>
      </div>

      <div class="flex gap-3 items-center">
        <!-- Position -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'position' | translate }}</span>
          <span>{{ nodeDetail?.position?.name ?? '--' }}</span>
        </div>

        <!-- Department -->
        <div class="flex flex-col gap-1 flex-1">
          <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'department' | translate }}</span>
          <span>{{ nodeDetail?.department?.name ?? '--' }}</span>
        </div>
      </div>

      <!-- Permission groups -->
      <div class="flex flex-col gap-1 flex-1">
        <span class="text-2 text-neutral-600">{{ translateDetailPrefix + 'permission-groups' | translate }}</span>
        <div class="flex gap-1">
          @if (nodeDetail?.groups?.length ?? 0 > 0) {
            @for (permission of nodeDetail?.groups; track $index) {
              <span class="px-2 py-0.5 bg-neutral-300 rounded-sm w-fit line-clamp-1 font-medium text-2">
                {{ permission?.name }}
              </span>
            }
          } @else {
            <span>--</span>
          }
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
