import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { AccountSettingService } from '@core/services/account-setting.service';
import { catchError, map, Observable, of, switchMap, timer } from 'rxjs';

export class CheckPasswordValidator {
  static createValidator(accountService: AccountSettingService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      return timer(600).pipe(
        switchMap(() =>
          accountService.verifyPassword(control.value).pipe(
            map(res => {
              return res?.data === false ? { currentPassword: true } : null;
            }),
            catchError(() => {
              return of({ currentPassword: true });
            })
          )
        )
      );
    };
  }
}
