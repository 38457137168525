<div class="flex flex-col h-content relative">
  <div class="flex gap-3 items-center px-8 py-5 justify-between bg-white border border-neutral-200">
    <span class="text-5 font-semibold">{{ translatePrefix + 'company-organization' | translate }}</span>
    <div *ngxPermissionsOnly="Permissions.ORGANIZATION_EDIT">
      @switch (viewChart) {
        @case (ViewOrganization.EDIT) {
          <div class="flex gap-3 items-center">
            <app-button
              [label]="'action.cancel' | translate"
              variant="outline-secondary"
              (onClick)="onCancelEdit()"
              size="sm"></app-button>
            <app-button [label]="'action.save' | translate" size="sm" (onClick)="onSaveChart()"></app-button>
          </div>
        }
        @case (ViewOrganization.VIEW) {
          <app-button
            [label]="'action.edit' | translate"
            size="sm"
            (onClick)="onEditChart()"
            icon="icon-wl-edit"
            iconPosition="left"></app-button>
        }
      }
    </div>
  </div>

  <!-- ORGANIZATION CHART -->
  <div class="p-6 w-full overflow-auto h-full">
    <p-organizationChart
      [value]="companyOrganizationState.data"
      [(selection)]="selectedNodes"
      selectionMode="single"
      [collapsible]="false"
      [preserveSpace]="false">
      <ng-template let-parent pTemplate="parent">
        <app-org-chart-item
          [typeNode]="parent.type"
          [chartList]="this.companyOrganizationState.data"
          [nodeItemData]="parent.data"
          [userList]="userList"
          [totalUser]="totalUser"
          (actionNodeItem)="onActionNodeItem($event)"
          (updateNewNodeItem)="onUpdateNodeItemNewData($event)"
          [(viewChart)]="viewChart"
          (touchedNodeItem)="onTouchedNodeItem($event)"></app-org-chart-item>
      </ng-template>
      <ng-template let-node pTemplate="node">
        <app-org-chart-item
          [typeNode]="node.type"
          [nodeItemData]="node.data"
          [userList]="userList"
          [totalUser]="totalUser"
          (actionNodeItem)="onActionNodeItem($event)"
          (updateNewNodeItem)="onUpdateNodeItemNewData($event)"
          [chartList]="this.companyOrganizationState.data"
          [(viewChart)]="viewChart"
          (touchedNodeItem)="onTouchedNodeItem($event)"></app-org-chart-item>
      </ng-template>

      <ng-template let-new pTemplate="new">
        <app-org-chart-add
          [parentId]="new.key"
          [chartList]="this.companyOrganizationState.data"
          [nodeItem]="new?.data"
          [userList]="userList"
          [totalUser]="totalUser"
          [(viewChart)]="viewChart"
          (actionNewNodeItem)="onActionNodeItemNewType($event)"
          (updateNewNodeItem)="onUpdateDataNodeItemNewType($event)"
          [(visibleNewNodeItem)]="visibleItemChart"
          (touchedNewNodeItem)="onTouchedNewItemNewType($event)"></app-org-chart-add>
      </ng-template>
    </p-organizationChart>
  </div>

  <!-- Loading -->
  @if (companyOrganizationState.loading) {
    <div class="absolute top-0 bottom-0 right-0 left-0 h-content flex justify-center items-center bg-neutral-600/15">
      <app-custom-loading></app-custom-loading>
    </div>
  }
</div>

<!-- Quick Action  -->
@if (viewChart === this.ViewOrganization.EDIT || viewChart === this.ViewOrganization.VIEW) {
  <app-org-chart-quick-action [(organizationChart)]="companyOrganizationState.data"></app-org-chart-quick-action>
}

@if (viewChart === this.ViewOrganization.VIEW) {
  <app-org-chart-detail-node [(visible)]="visibleDetailUser" [nodeDetail]="companyOrganizationState.currentNode">
  </app-org-chart-detail-node>
}
