<p-overlay
  (onHide)="onHide()"
  [(visible)]="visible"
  contentStyleClass="rounded-md shadow bg-white w-full"
  [appendTo]="appendTo">
  <div
    class="max-h-[250px] w-[220px]"
    [ngClass]="{ 'overflow-y-scroll !w-[250px]': userState?.data?.length ?? 0 > 0 }"
    infiniteScroll
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="2"
    [scrollWindow]="false"
    (scrolled)="onScroll()">
    <div class="flex flex-col relative">
      <div class="sticky top-0">
        <app-search-input
          inputClass="w-full focus:!shadow-none h-9 "
          [debounceTime]="600"
          (onSearch)="onSearch($event)"></app-search-input>
      </div>

      @for (option of userState?.data; track $index) {
        <div
          class="h-14 leading-10 hover:bg-neutral-200 w-full truncate"
          (click)="!mapIdsUserDisable(option.id) ? onSelectOptionUser(option) : null">
          <div
            class="flex flex-row items-start gap-2 p-2 rounded-sm"
            [ngClass]="mapIdsUserDisable(option.id) ? 'bg-neutral-200 ' : 'cursor-pointer'">
            <div class="w-5">
              @if (option?.id === userSelected?.id) {
                <i [ngClass]="mapIdsUserDisable(option.id) ? 'icon-wl-check text-xl text-blue-600' : null"></i>
              }
            </div>
            <app-custom-avatar [name]="option.fullName" [imgId]="option.avatar" size="sm"></app-custom-avatar>
            <div class="flex flex-col gap-0.5 h-fit" [ngClass]="{ '!text-neutral-500': mapIdsUserDisable(option.id) }">
              <span class="h-5 flex items-center">{{ option.fullName }}</span>
              <span class="text-neutral-500 text-sm h-5 flex items-center">{{ option.department?.name ?? '--' }}</span>
            </div>
          </div>
        </div>
      }
    </div>
    <!-- No data -->
    @if (userState?.data?.length === 0 && !userState.loading) {
      <div class="p-1">
        <app-empty-table></app-empty-table>
      </div>
    }
    @if (userState.loading) {
      <div class="flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 h-full bg-neutral-300/50">
        <i class="pi pi-spin pi-spinner w-5 h-5"></i>
      </div>
    }
  </div>
</p-overlay>
