<p-dropdown
  class="flex"
  [options]="languages"
  [(ngModel)]="selectedLanguage"
  optionLabel="name"
  filterBy="name"
  (onChange)="changeLocale(selectedLanguage)"
  panelStyleClass="translate-x-[-12px]">
  <ng-template pTemplate="selectedItem">
    <div class="country" *ngIf="selectedLanguage">
      <img
        class="country-flag"
        src="/assets/images/flag/flag_placeholder.png"
        [class]="'flag flag-' + selectedLanguage.code.toLowerCase()" />
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="country">
      <img
        class="country-flag"
        src="/assets/images/flag/flag_placeholder.png"
        [class]="'flag flag-' + country.code.toLowerCase()" />
      <div class="text-sm font-medium text-gray-700">{{ country.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
