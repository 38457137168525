import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  DashboardColorOptions,
  PolicyStatisticOptionsByIssuedPremium,
  PolicyStatisticOptionsByPremium,
  PolicyStatisticOptionsByTotalPremium,
  PolicyStatisticOptionsByTotalIssuedPremium
} from '@core/constants/dashboard';
import { POLICY_STATISTIC } from '@core/enum/dashboard';
import { KeyedValue } from '@core/models/interfaces/common';
import { AmountOption, ChartOption } from '@core/models/interfaces/dashboard';
import { DashboardService } from '@core/services/dashboard.service';
import { DoughnutChartLegendComponent } from '@feature/dashboard/components/doughnut-chart-legend/doughnut-chart-legend.component';
import { StatisticComponent } from '@feature/dashboard/components/statistic/statistic.component';
import {
  SunburstChartComponent,
  SunburstNodeType
} from '@feature/dashboard/components/sunburst-chart/sunburst-chart.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChartData, TooltipItem } from 'chart.js';
import moment from 'moment';
import { ChartModule } from 'primeng/chart';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-policy-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    ChartModule,
    SelectButtonModule,
    FormsModule,
    TranslateModule,
    StatisticComponent,
    DoughnutChartLegendComponent,
    SunburstChartComponent
  ],
  templateUrl: './policy-dashboard.component.html',
  styleUrl: './policy-dashboard.component.scss'
})
export class PolicyDashboardComponent implements OnChanges, OnDestroy {
  @Input() rangeDates: Date[] = [];
  @Input() userId?: string = '';
  @Input() userGroupId?: string = '';

  readonly COLORS = DashboardColorOptions;
  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'dashboard.';
  readonly doughnutChartOptions = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'doughnut'>) {
            const total = tooltipItem?.dataset?.data?.reduce((a: number, b: number) => a + b, 0) || 0;
            return `${tooltipItem?.formattedValue} (${(((tooltipItem?.parsed || 0) / total) * 100).toFixed(2)}%)`;
          }
        }
      }
    }
  };
  readonly productionChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },

      annotation: {
        annotations: {
          avgLine: {
            type: 'line',
            yMin: 0,
            yMax: 0,
            borderColor: '#EA580C',
            borderWidth: 2,
            label: {
              content: '',
              display: true,
              position: 'left',
              padding: 4
            }
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback: function (value: number, _: number) {
            return String(value);
          }
        }
      },
      y: {
        grid: {
          display: true,
          borderDash: [5, 5]
        },
        ticks: {
          callback: (value: number, _: number) => {
            return value;
          }
        }
      }
    }
  };
  readonly productionOptions: KeyedValue[] = [
    {
      title: 'Month',
      value: 'MONTH'
    },
    {
      title: 'Quarter',
      value: 'QUARTER'
    },
    {
      title: 'Year',
      value: 'YEAR'
    }
  ];
  readonly salesByStatusChartOptions = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          borderDash: [5, 5]
        }
      },
      y: {
        grid: {
          borderDash: [5, 5]
        },
        ticks: {
          beginAtZero: true,
          callback: (value: number, _: number) => {
            return value;
          }
        }
      }
    }
  };

  // STATISTICS
  policyStatisticsByTotalPremium = PolicyStatisticOptionsByTotalPremium;
  policyStatisticsByTotalIssuedPremium = PolicyStatisticOptionsByTotalIssuedPremium;
  policyStatisticsByPremium = PolicyStatisticOptionsByPremium;
  policyStatisticsByIssuedPremium = PolicyStatisticOptionsByIssuedPremium;

  // Production Chart
  productionActive: string = 'MONTH';
  productionChartData: ChartData;
  totalProduction: number = 0;

  // Product By Company
  productByCompanyChartData: SunburstNodeType;
  productByCompanyOptions: ChartOption[] = [];

  // Policies By Source
  policiesBySourceChartData: ChartData;
  policiesBySourceOptions: ChartOption[] = [];

  // Total Sales By Policy Status
  salesByStatusChartData: ChartData;
  salesByStatusOptions: ChartOption[] = [];

  // Policies By Status
  policiesByStatusChartData: SunburstNodeType;
  policiesByStatusOptions: ChartOption[] = [];

  // Policies By Tracking
  policiesByTrackingChartData: ChartData;
  policiesByTrackingOptions: ChartOption[] = [];

  // Policies By Company
  policiesByCompanyChartData: SunburstNodeType;
  policiesByCompanyOptions: ChartOption[] = [];

  constructor(private dashboardService: DashboardService) {}

  ngOnChanges() {
    this.fetchProductionChartData();
    this.fetchStatisticData();
    this.fetchPolicyChartsData();
  }

  get dateFrom() {
    return this.rangeDates?.[0] ? moment(this.rangeDates?.[0]).startOf('date').toDate().toISOString() : undefined;
  }

  get dateTo() {
    return this.rangeDates?.[1] ? moment(this.rangeDates?.[1]).endOf('date').toDate().toISOString() : undefined;
  }

  fetchStatisticData() {
    this.dashboardService
      .getPolicyStatisticalData({
        groupId: this.userGroupId,
        userId: this.userId,
        from: this.dateFrom,
        to: this.dateTo
      })
      .subscribe({
        next: res => {
          const data = res.data;
          // 1
          for (let option of this.policyStatisticsByTotalPremium) {
            switch (option.id) {
              case POLICY_STATISTIC.PREMIUM: {
                option.amount = data.premium?.amount;
                break;
              }
              case POLICY_STATISTIC.POLICIES_OF_PREMIUM: {
                option.amount = data.policiesOfPremium?.amount;
                break;
              }
            }
          }

          // 2
          for (let option of this.policyStatisticsByTotalIssuedPremium) {
            switch (option.id) {
              case POLICY_STATISTIC.ISSUED_PREMIUM: {
                option.amount = data.issuedPremium?.amount;
                break;
              }
              case POLICY_STATISTIC.POLICIES_OF_ISSUED_PREMIUM: {
                option.amount = data.policiesOfIssuedPremium?.amount;
                break;
              }
            }
          }

          // 3
          for (let option of this.policyStatisticsByPremium) {
            switch (option.id) {
              case POLICY_STATISTIC.PREMIUM_BY_TIME: {
                option.rate = data.premiumByTime?.rate;
                option.amount = data.premiumByTime?.amount;
                break;
              }
              case POLICY_STATISTIC.POLICIES_OF_PREMIUM_BY_TIME: {
                option.rate = data.policiesOfPremiumByTime?.rate;
                option.amount = data.policiesOfPremiumByTime?.amount;
                break;
              }
            }
          }

          // 4
          for (let option of this.policyStatisticsByIssuedPremium) {
            switch (option.id) {
              case POLICY_STATISTIC.ISSUED_PREMIUM_BY_TIME: {
                option.rate = data.issuedPremiumByTime?.rate;
                option.amount = data.issuedPremiumByTime?.amount;
                break;
              }
              case POLICY_STATISTIC.POLICIES_OF_ISSUED_PREMIUM_BY_TIME: {
                option.rate = data.policiesOfIssuedPremiumByTime?.rate;
                option.amount = data.policiesOfIssuedPremiumByTime?.amount;
                break;
              }
            }
          }
        }
      });
  }

  fetchProductionChartData() {
    this.dashboardService
      .getPolicyProductionChartData(this.productionActive, this.userId, this.userGroupId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          const labels = res.data.map(item => item.periodName);
          const totalData = res.data.map(item => item.total);
          const totalIssuePremiumData = res.data.map(item => item.totalIssuePremium);

          const totalProduction = totalData.reduce((p, c) => p + c, 0);
          const totalIssuePremiumProduction = totalIssuePremiumData.reduce((p, c) => p + c, 0);

          this.totalProduction = totalIssuePremiumProduction;
          const average =
            (totalProduction + totalIssuePremiumProduction) / (totalData.length + totalIssuePremiumData.length);

          this.productionChartData = {
            labels,
            datasets: [
              {
                label: 'Premium',
                backgroundColor: '#3B82F6',
                data: totalData,
                type: 'bar',
                maxBarThickness: 40
              },
              {
                label: 'Issued premium',
                backgroundColor: '#EF4444',
                data: totalIssuePremiumData,
                type: 'bar',
                maxBarThickness: 40
              }
            ]
          };

          this.productionChartOptions.scales.x.ticks = {
            callback: (_: number, index: number) => {
              if (this.productionActive === 'MONTH') {
                if ((index + 1) % 5 === 0 || index === 0) {
                  return labels[index];
                } else {
                  return '';
                }
              } else {
                return labels[index];
              }
            }
          };

          this.productionChartOptions.plugins.annotation.annotations = {
            avgLine: {
              type: 'line',
              yMin: average,
              yMax: average,
              borderColor: '#EA580C',
              borderWidth: 1,
              label: {
                content: average.toFixed(2),
                display: true,
                position: 'start',
                padding: 4
              }
            }
          };
        }
      });
  }

  fetchPolicyChartsData() {
    this.dashboardService
      .getPolicyChartData({ userId: this.userId, groupId: this.userGroupId, from: this.dateFrom, to: this.dateTo })
      .subscribe({
        next: res => {
          const productionByCompany = res.data.productionByCompany;
          const salesByStatus = res.data.salesByStatus;
          const policyByStatus = res.data.status;
          const policiesByTracking = res.data.tracking;
          const policiesByCompany = res.data.company;
          const policiesBySource = res.data.sources;

          // Product By Company
          if (productionByCompany) {
            this.productByCompanyChartData = {
              name: 'root',
              children: this.convertToSunburstNode(productionByCompany)
            };
            this.productByCompanyOptions = productionByCompany.map((item, index) => ({
              color: this.COLORS[index % 10].bold,
              data: item.amount,
              label: item.option.name
            }));
          }

          // Product By Source
          if (policiesBySource) {
            this.policiesBySourceOptions = policiesBySource
              .sort((a, b) => b.amount - a.amount)
              .map((item, index) => ({
                label: item.option.name,
                color: this.COLORS[index % 10].normal,
                data: item.amount
              }));

            this.policiesBySourceChartData = {
              labels: this.policiesBySourceOptions.map(item => item.label),
              datasets: [
                {
                  data: this.policiesBySourceOptions.map(item => item.data),
                  backgroundColor: this.policiesBySourceOptions.map(item => item.color)
                }
              ]
            };
          }

          // Total Sales By Status
          if (salesByStatus.length) {
            this.salesByStatusChartData = {
              labels: salesByStatus.map(item => item.name),
              datasets: salesByStatus[0].options.map((item, index) => {
                return {
                  label: item.option.name,
                  backgroundColor: item.option.color || this.COLORS[index % 10].normal,
                  data: salesByStatus.map(item => item.options[index].amount)
                };
              })
            };

            this.salesByStatusOptions = salesByStatus[0].options.map((item, index) => {
              return {
                label: item.option.name,
                color: item.option.color || this.COLORS[index % 10].normal,
                data: item.amount
              };
            });
          }

          // Policies By Status
          if (policyByStatus) {
            this.policiesByStatusChartData = {
              name: 'root',
              children: this.convertToSunburstNode(policyByStatus)
            };
            this.policiesByStatusOptions = policyByStatus.map((item, index) => {
              return {
                label: item.option.name,
                color: this.COLORS[index % 10].bold,
                data: item.amount
              };
            });
          }

          //Policies By Tracking
          if (policiesByTracking) {
            this.policiesByTrackingOptions = policiesByTracking
              .sort((a, b) => b.amount - a.amount)
              .map((item, index) => ({
                label: item.option.name,
                color: this.COLORS[index % 10].normal,
                data: item.amount
              }));

            this.policiesByTrackingChartData = {
              labels: this.policiesByTrackingOptions.map(item => item.label),
              datasets: [
                {
                  data: this.policiesByTrackingOptions.map(item => item.data),
                  backgroundColor: this.policiesByTrackingOptions.map((_, index) => this.COLORS[index % 10].normal)
                }
              ]
            };
          }

          //Policies By Company
          if (policiesByCompany) {
            this.policiesByCompanyOptions = policiesByCompany
              .sort((a, b) => b.amount - a.amount)
              .map((item, index) => {
                return {
                  label: item.option.name,
                  color: this.COLORS[index % 10].normal,
                  data: item.amount
                };
              });
            this.policiesByCompanyChartData = {
              name: 'root',
              children: this.convertToSunburstNode(policiesByCompany)
            };
          }
        }
      });
  }

  convertToSunburstNode(data: AmountOption[], colorIndex = 0): SunburstNodeType[] {
    return data.map((item, index) => {
      const currentColor = this.COLORS[(colorIndex + index) % 10].bold;
      return {
        name: item.option.name,
        color: currentColor,
        value: item.children.length ? undefined : item.amount,
        children:
          item.children.length > 0 ? this.convertToSunburstNodeWithParentColor(item.children, currentColor, 1) : []
      };
    });
  }

  convertToSunburstNodeWithParentColor(data: AmountOption[], parentColor: string, level: number): SunburstNodeType[] {
    const colorIndex = this.COLORS.findIndex(
      color => (level === 1 && color.bold === parentColor) || (level === 2 && color.normal === parentColor)
    );

    return data.map(item => {
      let currentColor: string = '';

      if (level === 1) {
        currentColor = this.COLORS[colorIndex % 10].normal;
      } else if (level === 2) {
        currentColor = this.COLORS[colorIndex % 10].light;
      }
      return {
        name: item.option.name,
        color: currentColor,
        value: item.children.length ? undefined : item.amount,
        children:
          item.children.length > 0
            ? this.convertToSunburstNodeWithParentColor(item.children, currentColor, level + 1)
            : undefined
      };
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
