export enum PerformanceSortFieldEnum {
  FULLNAME = 'fullName',
  NOTE = 'notes',
  CLICK_CALL = 'clickCalls',
  UPDATED_LEAD = 'updatedLeads',
  NO_UPDATED_LEAD = 'noUpdatedLeads',
  TASK = 'tasks',
  APPOINTMENT = 'appointments',
  LEAD_CREATED = 'createdLeads',
  POLICY_CREATED = 'createdPolicies',
  HOURS_ONLINE = 'hoursOnline'
}

export enum LeadSortFieldEnum {
  FULLNAME = 'fullName',
  LEAD_MOVED = 'leadMoved',
  LEAD_TAKE_CARE = 'leadTakeCare',
  LEAD_CREATED = 'leadCreated',
  CUSTOMER = 'customer',
  PROSPECT = 'prospect',
  HIDDEN = 'hidden'
}

export enum ProductionSortFieldEnum {
  POLICY_NO = 'number',
  LEAD = 'lead.fullName',
  CREATED_DATE = 'createdAt',
  OWNER = 'owner.fullName',
  AGENT = 'agent.fullName',
  POLICY_STATUS = 'status.name',
  POLICY_TRACKING = 'policyTracking.name',
  EFFECTIVE_DATE = 'effectiveDate',
  MODE = 'mode.name',
  PAYMENT = 'payment',
  FACE_AMOUNT = 'faceAmount',
  COMPANY = 'product.productType.company.name',
  PRODUCT_TYPE = 'product.productType.name',
  PRODUCT_NAME = 'product.name',
  CONTRIBUTION_YEAR = 'contributionYear',
  COMMISSION_TEAM = 'commissionTeam.name',
  COMMISSION_PERCENTAGE = 'commissionPercentage.name',
  COMMISSION_OWNER = 'commissionOwner.fullName',
  COMMISSION_AGENT = 'commissionAgent.fullName',
  CREATED_BY = 'createdBy.fullName',
  TARGET = 'target'
}

export enum ReportTabEnum {
  PRODUCT = 'PRODUCT',
  PERFORMANCE = 'PERFORMANCE',
  LEAD = 'LEAD'
}
