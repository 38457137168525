import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { APP_VERSION } from '@core/constants/common';
import { ReportService } from '@core/services/report.service';
import { HeaderComponent } from '@shared/components/header/header.component';
import { SidebarComponent } from '@shared/components/sidebar/sidebar.component';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, SidebarComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnInit {
  readonly appVersion = APP_VERSION;

  constructor(private reportService: ReportService) {}

  ngOnInit() {
    //Call start
    this.reportService.logEventReport('ONLINE').subscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    if (event?.target?.activeElement?.tagName === 'A') {
      return;
    }
    // Call end
    this.reportService.logEventReport('end-online').subscribe();
  }
}
