import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TextPattern } from '@core/constants/validate-pattern';
import { APP_PERMISSION } from '@core/enum/permisson';
import { NewFeedDetail } from '@core/models/interfaces/home-page';
import { NewFeedService } from '@core/services/amin-content/new-feed.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AdminContentPostEditComponent } from '../admin-content-post-edit/admin-content-post-edit.component';
import { AdminContentSortFieldEnum } from '@core/enum/admin-content';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';

@Component({
  selector: 'app-admin-content-post',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TableModule,
    SearchInputComponent,
    ButtonComponent,
    CustomAvatarComponent,
    TooltipModule,
    CustomPaginatorComponent,
    AdminContentPostEditComponent,
    CustomImageComponent,
    EmptyTableComponent,
    NgxPermissionsModule,
    SortTableHeaderComponent
  ],
  templateUrl: './admin-content-post.component.html',
  styleUrl: './admin-content-post.component.scss'
})
export default class AdminContentPostComponent {
  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();
  readonly Permissions = APP_PERMISSION;
  readonly AdminContentSortFieldEnum = AdminContentSortFieldEnum;
  isVisibleCreatePost: boolean = false;
  sortOrder: number = 0;
  sortField: string = '';
  pagination = {
    page: 0,
    size: 10
  };

  totalPins: number = 0;
  postManagementState: { posts: NewFeedDetail[]; totalPost: number; loading: boolean; keyword: string } = {
    posts: [],
    totalPost: 0,
    loading: false,
    keyword: ''
  };

  postDetail?: NewFeedDetail;

  constructor(
    private newFeedService: NewFeedService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.fetchPostsManagement(true);
  }

  onSort(event: { field: string; order: number }) {
    this.sortOrder = event.order;
    this.sortField = event.field;
    this.fetchPostsManagement();
  }

  fetchPostsManagement(isFirstLoad: boolean = false) {
    const sorts = [];
    sorts.push('-isPin');
    if (this.sortField) {
      sorts.push(this.sortOrder < 0 ? this.sortField : `-${this.sortField}`);
    }
    const sortsString = sorts.join('|');
    this.postManagementState.loading = true;
    this.newFeedService
      .getNewFeedManagement({
        keyword: this.postManagementState.keyword,
        page: this.pagination.page,
        size: this.pagination.size,
        sorts: sortsString
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.postManagementState = {
            ...this.postManagementState,
            posts: res?.data?.content,
            totalPost: res?.data?.total,
            loading: false
          };

          if (isFirstLoad) {
            this.totalPins = this.postManagementState.posts.filter(el => el.isPin).length;
          }
        },
        error: () => {
          this.postManagementState.loading = false;
        }
      });
  }

  onCreatePost() {
    this.isVisibleCreatePost = true;
  }

  onSearch(keyword: string) {
    this.pagination.page = 0;
    this.postManagementState.keyword = keyword;
    this.fetchPostsManagement();
  }

  onEditPost(post: NewFeedDetail) {
    this.isVisibleCreatePost = true;
    this.postDetail = post;
  }

  onCloseModal() {
    this.postDetail = undefined;
  }

  onDeletePost(post: NewFeedDetail) {
    this.confirmationService.confirm({
      message: this.translateService.instant(this.translatePrefix + 'confirm-delete', {
        name: post.title
      }),
      header: this.translateService.instant(this.translatePrefix + 'delete-post'),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',
      accept: () => {
        this.newFeedService
          .deleteNewFeed(post?.id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant(this.translatePrefix + 'delete-post-success')
              });
              this.fetchPostsManagement();
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: this.translateService.instant(this.translatePrefix + 'delete-post-fail')
              });
            }
          });
      }
    });
  }

  onSuccess() {
    this.pagination.page = 0;
    this.fetchPostsManagement();
  }

  onPinPost(post: NewFeedDetail) {
    this.newFeedService
      .updateBookmark(post.id, !post.isPin)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          const index = this.postManagementState.posts.findIndex(item => item.id === post.id);
          this.postManagementState.posts[index].isPin = !post.isPin;
          // this.fetchPostsManagement();

          if (post.isPin) {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'pin-post-success')
            });
            this.totalPins++;
          } else {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'unpin-post-success')
            });
            this.totalPins--;
          }
        },
        error: err => {
          this.messageService.add({
            severity: 'error',
            detail:
              err?.error?.error ||
              this.translateService.instant(this.translatePrefix + (!post.isPin ? 'pin-post-fail' : 'unpin-post-fail'))
          });
        }
      });
  }

  domSanitizer(value: string) {
    return value.replace(TextPattern, '');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
