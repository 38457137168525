import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ADMIN_CONTENT_TAB } from '@core/enum/admin-content';
import { APP_PERMISSION } from '@core/enum/permisson';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { NgxPermission } from 'ngx-permissions/lib/model/permission.model';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';

import { AdminContentBannerComponent } from './components/admin-content-banner/admin-content-banner.component';
import { AdminContentLinkComponent } from './components/admin-content-link/admin-content-link.component';
import AdminContentPostComponent from './components/admin-content-post/admin-content-post.component';

@Component({
  selector: 'app-admin-content',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TabViewModule,
    SearchInputComponent,
    ButtonComponent,
    AdminContentBannerComponent,
    AdminContentPostComponent,
    AdminContentLinkComponent,
    NgxPermissionsModule
  ],
  templateUrl: './admin-content.component.html',
  styleUrl: './admin-content.component.scss'
})
export class AdminContentComponent {
  readonly translatePrefix = 'admin-content.';
  readonly Permissions = APP_PERMISSION;
  readonly ADMIN_CONTENT_TAB = ADMIN_CONTENT_TAB;

  permissionTabs: Array<{
    tab: ADMIN_CONTENT_TAB;
    permission?: NgxPermission;
  }> = [];

  activeIndex: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngxPermissionsService: NgxPermissionsService
  ) {}

  ngOnInit(): void {
    const tab = this.route.snapshot.queryParams['tab'];
    this.permissionTabs = [
      { tab: ADMIN_CONTENT_TAB.POST, permission: this.ngxPermissionsService.getPermission(APP_PERMISSION.POST_VIEW) },
      { tab: ADMIN_CONTENT_TAB.LINK, permission: this.ngxPermissionsService.getPermission(APP_PERMISSION.LINK_VIEW) },
      {
        tab: ADMIN_CONTENT_TAB.BANNER,
        permission: this.ngxPermissionsService.getPermission(APP_PERMISSION.BANNER_VIEW)
      }
    ];

    const foundTab = this.permissionTabs.find(item => (tab ? tab === item.tab : true) && item.permission);
    this.activeIndex = foundTab ? this.permissionTabs.indexOf(foundTab) : 0;
  }

  onTabChange(event: TabViewChangeEvent) {
    this.activeIndex = event.index;
    let tab = this.permissionTabs[this.activeIndex].tab;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab
      }
    });
  }
}
