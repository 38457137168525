import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class CustomPermissionsGuard implements CanActivate {

  constructor(private permissionsService: NgxPermissionsService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const requiredPermissions = route.data['permissions']?.only || [];
    const userPermissions = await this.permissionsService.getPermissions();

    const hasAllPermissions = requiredPermissions.every((permission: string) => !!userPermissions[permission]);

    if (hasAllPermissions) {
      return true;
    } else {
      this.router.navigate([route.data['permissions']?.redirectTo || '/home']);
      return false;
    }
  }
}