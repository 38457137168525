import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NodeLevel, ViewOrganization } from '@core/enum/company-organization';
import { APP_PERMISSION } from '@core/enum/permisson';
import {
  CompanyOrganizationState,
  NodeChart,
  OrganizationCard,
  OrganizationImportBody
} from '@core/models/interfaces/company-organization';
import { UserDetail } from '@core/models/interfaces/user';
import { OrganizationService } from '@core/services/organization.service';
import { UserService } from '@core/services/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MessageService, TreeNode } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { of, switchMap } from 'rxjs';
import { OrgChartAddComponent } from './components/org-chart-add/org-chart-add.component';
import { OrgChartDetailNodeComponent } from './components/org-chart-detail-node/org-chart-detail-node.component';
import { OrgChartItemComponent } from './components/org-chart-item/org-chart-item.component';
import { OrgChartQuickActionComponent } from './components/org-chart-quick-action/org-chart-quick-action.component';

@Component({
  selector: 'app-company-organization',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    OrganizationChartModule,
    OrgChartItemComponent,
    OrgChartAddComponent,
    DividerModule,
    OrgChartDetailNodeComponent,
    CustomLoadingComponent,
    OrgChartQuickActionComponent,
    NgxPermissionsModule
  ],
  templateUrl: './company-organization.component.html',
  styleUrl: './company-organization.component.scss'
})
export class CompanyOrganizationComponent {
  readonly translatePrefix = 'company-organization.';
  readonly ViewOrganization = ViewOrganization;
  readonly Permissions = APP_PERMISSION;

  companyOrganizationState: CompanyOrganizationState = {
    loading: false,
    data: [],
    totalOrigin: 0,
    currentNode: undefined,
    chartsOrigin: []
  };

  viewChart: ViewOrganization = this.ViewOrganization.VIEW;

  selectedNodes!: TreeNode;
  listNodeUpdate: OrganizationImportBody[] = [];

  visibleItemChart: boolean = true;
  visibleDetailUser: boolean = false;

  userList: UserDetail[];
  totalUser: number = 0;

  constructor(
    private userService: UserService,
    private organizationService: OrganizationService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchUser();
    this.fetchChartOrganization();
  }

  fetchUser() {
    this.userService.getUsers().subscribe({
      next: res => {
        this.userList = res?.data?.content ?? [];
        this.totalUser = res?.data?.total;
      }
    });
  }

  fetchChartOrganization() {
    this.companyOrganizationState.loading = true;
    this.organizationService.getOrgChart().subscribe({
      next: res => {
        if (res?.data.length > 0) {
          this.companyOrganizationState = {
            data: this.convertToTreeNode(res?.data),
            loading: false,
            totalOrigin: res?.data?.length ?? 0,
            chartsOrigin: res.data
          };
        } else {
          const defaultNode: TreeNode = {
            checked: false,
            children: [],
            data: {
              idNew: crypto.randomUUID(),
              parentId: null,
              isHiring: false
            },
            expanded: true,
            key: crypto.randomUUID(),
            type: 'new'
          };

          this.companyOrganizationState = {
            data: [defaultNode],
            loading: false,
            totalOrigin: 0
          };
        }
      }
    });
  }

  convertToTreeNode(users: NodeChart[]): TreeNode[] {
    const map: { [key: string]: TreeNode } = {};

    const treeData: TreeNode[] = users
      .map(user => {
        if (!user.userId) {
          return null;
        }

        const node: TreeNode = {
          key: user?.id,
          data: { ...user.userRepresentation, parentId: user.parentId, isHiring: user.isHiring },
          children: [],
          type: 'node',
          expanded: true,
          checked: user?.isHiring ?? false
        };

        if (!node.data.id) {
          node.data.id = crypto.randomUUID();
        }
        map[user.userId] = node;
        return node;
      })
      .filter(node => node !== null) as TreeNode[];

    const roots: TreeNode[] = [];
    users.forEach(user => {
      if (user.userId && user.parentId === null) {
        const rootNode = map[user.userId];
        rootNode.type = 'parent';
        roots.push(rootNode);
      } else if (user.userId && user.parentId && map[user.parentId]) {
        if (map[user.userId] !== map[user.parentId]) {
          map[user.parentId].children!.push(map[user.userId]);
        }
      }
    });

    return roots;
  }

  onRemoveNode(nodes: TreeNode[], nodeId: string, data: OrganizationCard): TreeNode[] {
    return nodes.filter(node => {
      if (node.children) {
        node.children = this.onRemoveNode(node.children, nodeId, data);
      }

      if (!nodeId) {
        return node.data?.idNew !== data?.idNew;
      }

      if (this.companyOrganizationState.data[0].data.id === nodeId) {
        this.companyOrganizationState.data = [];

        const defaultNode: TreeNode = {
          checked: false,
          children: [],
          data: {
            idNew: crypto.randomUUID(),
            parentId: null,
            isHiring: false
          },
          expanded: true,
          key: crypto.randomUUID(),
          type: 'new'
        };

        this.companyOrganizationState.data = [defaultNode];
        return [defaultNode];
      }

      return node.data?.id !== nodeId;
    });
  }

  onEditChart() {
    this.viewChart = this.ViewOrganization.EDIT;
    // Initialize the first node
    if (!this.companyOrganizationState.data || this.companyOrganizationState.data.length === 0) {
      this.onSaveChart();
    }
  }

  onCancelEdit() {
    this.viewChart = this.ViewOrganization.VIEW;
    this.fetchChartOrganization();
  }

  onActionNodeItem(value: { node: NodeLevel; data: OrganizationCard }) {
    switch (value.node) {
      case NodeLevel.CHILD_LEVEL:
        this.addChildNode(this.companyOrganizationState.data, value?.data);
        this.visibleItemChart = true;
        break;

      case NodeLevel.SAME_LEVEL:
        this.addSiblingNode(this.companyOrganizationState.data, value.data);
        this.visibleItemChart = true;
        break;

      case NodeLevel.NONE:
        this.onRemoveNode(this.companyOrganizationState.data, value?.data?.id ?? '', value?.data);
        break;
    }
    this.viewChart = this.ViewOrganization.EDIT;
  }

  onTouchedNodeItem(value: OrganizationCard) {
    if (value) {
      this.setAllIsFocus(this.companyOrganizationState.data);
      value.isFocus = true;
      this.companyOrganizationState.currentNode = value;
    } else {
      this.visibleDetailUser = true;
    }
  }

  setAllIsFocus(nodes: TreeNode[]) {
    nodes?.forEach(node => {
      if (node.data && node.data.hasOwnProperty('isFocus')) {
        node.data.isFocus = false;
      }

      if (node.children && node.children.length > 0) {
        this.setAllIsFocus(node.children);
      }
    });
  }

  addSiblingNode(nodes: TreeNode[], nodeItem: OrganizationCard): void {
    nodes?.forEach(node => {
      if (node.children?.length) {
        const foundIndex = node.children.findIndex(child => child.data?.id === nodeItem?.id);
        if (foundIndex !== -1) {
          this.setAllIsFocus(this.companyOrganizationState.data);
          node.children.push({
            expanded: true,
            type: 'new',
            data: {
              idNew: crypto.randomUUID(),
              image: '',
              name: '',
              department: '',
              isHiring: false,
              position: '',
              isFocus: true,
              isInvalid: false
            }
          });
        } else {
          this.addSiblingNode(node.children, nodeItem);
        }
      }
    });
  }

  addChildNode(nodes: TreeNode[], nodeItem: OrganizationCard): void {
    nodes.forEach(node => {
      if (node?.data?.id === nodeItem.id) {
        this.setAllIsFocus(this.companyOrganizationState.data);
        if (!node.children) {
          node.children = [];
        }
        node.children.push({
          expanded: true,
          type: 'new',
          key: nodeItem.id,
          checked: false,
          data: {
            idNew: crypto.randomUUID(),
            image: '',
            name: '',
            department: '',
            isHiring: false,
            position: '',
            isFocus: true,
            isInvalid: false
          }
        });
      } else if (node.children?.length) {
        this.addChildNode(node.children, nodeItem);
      }
    });
    this.companyOrganizationState.data = [...this.companyOrganizationState.data];
  }

  onActionNodeItemNewType(value: { node: NodeLevel; nodeItem: OrganizationCard; action: NodeLevel }) {
    switch (value?.node) {
      case NodeLevel.NONE:
        this.onRemoveNode(this.companyOrganizationState.data, value?.nodeItem?.id ?? '', value.nodeItem);
        break;

      case NodeLevel.CHILD_LEVEL:
        this.addChildNode(this.companyOrganizationState.data, value?.nodeItem);
        this.visibleItemChart = true;
        break;

      case NodeLevel.SAME_LEVEL:
        this.addSiblingNode(this.companyOrganizationState.data, value?.nodeItem);
        this.visibleItemChart = true;
        break;
    }
    this.companyOrganizationState.data;
  }

  onUpdateDataNodeItemNewType(value: { node: NodeLevel; nodeItem: OrganizationCard; action: NodeLevel }) {
    const newTypeNodes = this.findNewTypeNodes(this.companyOrganizationState.data);

    newTypeNodes.forEach(node => {
      const nodeItemData = node.data as OrganizationCard;

      if (nodeItemData?.idNew === value.nodeItem?.idNew) {
        if (value.nodeItem.isHiring) {
          // Reset to default
          node.data = {
            isInvalid: false,
            idNew: crypto.randomUUID(),
            id: crypto.randomUUID(),
            parentId: node.key,
            isHiring: true,
            isFocus: true
          };
        } else {
          node.data = { ...node.data, ...value.nodeItem, isInvalid: false };
        }
      }
    });
  }

  onTouchedNewItemNewType(value: OrganizationCard) {
    this.setAllIsFocus(this.companyOrganizationState.data);
    value.isFocus = true;
  }

  findNewTypeNodes(nodes: TreeNode[]): TreeNode[] {
    let result: TreeNode[] = [];
    nodes.forEach(node => {
      if (node.type === 'new') {
        result.push(node);
      }

      if (node.children && node.children.length > 0) {
        result = result.concat(this.findNewTypeNodes(node.children));
      }
    });

    return result;
  }

  traverseTree(nodes: TreeNode<OrganizationCard>[], parentId: string | null = null): OrganizationImportBody[] {
    const result: OrganizationImportBody[] = [];

    nodes.forEach(node => {
      const { data } = node;
      const newNode: OrganizationImportBody = {
        idNode: node.key,
        parentId: parentId,
        userId: data?.id!,
        isHiring: data?.isHiring ?? false
      };
      result.push(newNode);

      if (node.children) {
        result.push(...this.traverseTree(node.children, data?.id));
      }
    });

    return result;
  }

  onUpdateNodeItemNewData(value: { data: OrganizationCard; currentData: OrganizationCard }) {
    const updateTree = (nodes: TreeNode[]) => {
      nodes.forEach((node, index) => {
        if (node.data.id === value.currentData.id) {
          if (value.currentData.isHiring) {
            // Reset to default
            node.data = {
              isInvalid: false,
              idNew: crypto.randomUUID(),
              id: crypto.randomUUID(),
              parentId: node.key,
              isHiring: true,
              isFocus: true
            };
          } else {
            node.data = value.data;
          }
        }
        if (node.children && node.children.length > 0) {
          updateTree(node.children);
        }
        if (node.data?.parentId === value.currentData?.id) {
          node.data.parentId = value.data?.id;
        }
      });
    };
    updateTree(this.companyOrganizationState.data);
  }

  checkUserIdAndSetInvalid(data: TreeNode[]): void {
    data.forEach(item => {
      if (!item.data.userId && !item.data.isHiring) {
        item.data.isInvalid = true;
      }
      if (item.children && item.children.length > 0) {
        this.checkUserIdAndSetInvalid(item.children);
      }
    });
  }

  onSaveChart() {
    const nodes = this.companyOrganizationState.data;
    const result = this.traverseTree(nodes);

    const newData = result.map(({ parentId, userId, isHiring }) => ({
      parentId,
      userId,
      isHiring
    }));
    this.checkUserIdAndSetInvalid(nodes);

    const isInValid = newData.some(el => !el.userId);

    if (isInValid) return;

    const idRoot = this.companyOrganizationState.chartsOrigin?.find(el => el.parentId === null)?.id;
    this.companyOrganizationState.loading = true;

    const source =
      this.companyOrganizationState.totalOrigin > 0 ? this.organizationService.deleteFromOrgChart(idRoot!) : of([]);

    source
      .pipe(
        switchMap(() =>
          newData.length === 1 && !newData[0].userId ? of([]) : this.organizationService.updateToOrgChart(newData)
        )
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.translatePrefix + 'update-organization-chart-success')
          });
          this.companyOrganizationState.loading = false;
          this.onCancelEdit();
        },
        error: () => {
          this.companyOrganizationState.loading = false;
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.translatePrefix + 'update-organization-chart-fail')
          });
        }
      });
  }
}
