<div class="bg-white min-h-content py-5">
  <div class="px-8">
    <span class="text-5 font-semibold">{{ translatePrefix + 'admin-content' | translate }}</span>
  </div>

  <!-- TAB VIEW -->
  <div>
    <p-tabView [activeIndex]="activeIndex" (onChange)="onTabChange($event)">
      <p-tabPanel [header]="translatePrefix + 'posts' | translate" *ngxPermissionsOnly="Permissions.POST_VIEW">
        @if (activeIndex === 0) {
          <app-admin-content-post></app-admin-content-post>
        }
      </p-tabPanel>
      <p-tabPanel [header]="translatePrefix + 'links' | translate" *ngxPermissionsOnly="Permissions.LINK_VIEW">
        @if (activeIndex === 1) {
          <app-admin-content-link></app-admin-content-link>
        }
      </p-tabPanel>
      <p-tabPanel [header]="translatePrefix + 'banners' | translate" *ngxPermissionsOnly="Permissions.BANNER_VIEW">
        @if (activeIndex === 2) {
          <app-admin-content-banner></app-admin-content-banner>
        }
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
