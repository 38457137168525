<div
  #orgChartItem
  (click)="onClickItem(nodeItemData)"
  class="p-3 flex gap-3 w-[220px] min-h-18 rounded-sm border border-gray-300 relative hover:bg-neutral-100"
  [ngClass]="{
    'ring-2  ring-blue-500 ring-offset-1': nodeItemData?.isFocus,
    'bg-[#FFEDD5] hover:!bg-[#FFF7ED] hover:border-orange-50 border-orange-100':
      nodeItemData.isHiring && viewChart === ViewOrganization.VIEW
  }">
  <!-- Button Close -->
  @if (viewChart === ViewOrganization.EDIT && nodeItemData?.isFocus) {
    <div
      class="bg-neutral-700 rounded-full absolute p-0.5 flex h-4 w-4 justify-center items-center -top-2 -right-2 cursor-pointer"
      (click)="onCloseNodeItem(nodeItemData)">
      <i class="icon-wl-close text-2 text-white leading-3"></i>
    </div>
  }

  <!-- Avatar -->
  @if (nodeItemData?.status?.code !== StatusUser.HIDDEN && !nodeItemData.isHiring) {
    <app-custom-avatar
      [imgId]="nodeItemData?.avatar"
      [name]="nodeItemData?.fullName ?? ''"
      size="md"></app-custom-avatar>
  } @else {
    <div class="p-1.5 bg-neutral-300 rounded-full h-8 w-8 flex items-center justify-center">
      <i class="icon-wl-user text-6"></i>
    </div>
  }

  <!-- Detail -->
  <div class="flex flex-col gap-2 justify-start">
    <div class="flex flex-col items-start gap-0.5 w-full">
      <!-- Name -->
      <span class="text-2 font-semibold text-neutral-900">{{
        nodeItemData?.status?.code === StatusUser.HIDDEN
          ? (translatePrefix + 'name' | translate)
          : nodeItemData?.fullName || (translatePrefix + 'name' | translate)
      }}</span>
      <div class="flex flex-col items-start">
        <!-- Position -->
        <span class="text-2 text-neutral-900">{{
          nodeItemData?.status?.code === StatusUser.HIDDEN
            ? (translatePrefix + 'position' | translate)
            : nodeItemData?.position?.name || (translatePrefix + 'position' | translate)
        }}</span>

        <!-- Department -->
        <span class="text-2 text-neutral-600">{{
          nodeItemData?.status?.code === StatusUser.HIDDEN
            ? (translatePrefix + 'department' | translate)
            : nodeItemData?.department?.name || (translatePrefix + 'department' | translate)
        }}</span>
      </div>
    </div>

    <!-- Tag Hiring -->
    @if (viewChart === ViewOrganization.EDIT && nodeItemData?.isFocus) {
      <div class="flex gap-1.5 items-center" (click)="onHiringEvent($event)">
        <p-checkbox
          styleClass="!w-4.5 !h-4.5"
          [binary]="true"
          [(ngModel)]="nodeItemData.isHiring"
          inputId="hiring"
          (onChange)="isChecked($event)" />
        <label class="text-2 font-medium" for="hiring">{{ translatePrefix + 'is-hiring' | translate }}</label>
      </div>
    }
  </div>
  @if (nodeItemData.isHiring && viewChart === ViewOrganization.VIEW) {
    <div
      class="absolute bottom-0 right-0 font-semibold text-1 bg-[#F67216] text-white px-1.5 rounded-tl rounded-br py-[1px]">
      {{ translatePrefix + 'hiring' | translate | uppercase }}
    </div>
  }
</div>
<!-- Actions -->
@if (viewChart === ViewOrganization.EDIT && nodeItemData?.isFocus) {
  @if (typeNode === 'parent') {
    <!-- Button Add Sub -->
    <div
      class="p-1 rounded-full bg-neutral-100 w-fit h-6 absolute flex justify-center items-center -bottom-9 left-18 cursor-pointer hover:bg-white text-neutral-600"
      (click)="onAddNode(NodeLevel.CHILD_LEVEL, nodeItemData)">
      <i class="icon-wl-plus text-4"></i
      ><span class="truncate text-2 font-medium">{{ translatePrefix + 'add-sub' | translate }}</span>
    </div>
  } @else {
    <!-- Button Same Level Page -->
    <div
      class="p-1 rounded-full bg-neutral-100 w-fit h-6 absolute flex justify-center items-center -right-32 top-8.5 cursor-pointer hover:bg-white"
      (click)="onAddNode(NodeLevel.SAME_LEVEL, nodeItemData)">
      <div class="bg-neutral-100 hover:bg-white flex justify-center rounded-full items-center text-neutral-600">
        <i class="icon-wl-plus text-4"></i
        ><span class="truncate text-2 font-medium w-full">{{ translatePrefix + 'same-level-page' | translate }}</span>
      </div>
    </div>

    <!-- Button Add Sub -->
    <div
      class="p-1 rounded-full w-fit h-6 absolute flex justify-center items-center -bottom-9 left-18 cursor-pointer text-neutral-600 bg-neutral-100 hover:bg-white"
      (click)="onAddNode(NodeLevel.CHILD_LEVEL, nodeItemData)">
      <i class="icon-wl-plus text-4"></i
      ><span class="truncate text-2 font-medium">{{ translatePrefix + 'add-sub' | translate }}</span>
    </div>
  }
}

<app-org-chart-dropdown-option
  [userIdsSelected]="idsUserSelected"
  [(userState)]="userState"
  [(visible)]="isVisible"
  [appendTo]="orgChartItem"
  (optionSelected)="onSelectedOption($event)">
</app-org-chart-dropdown-option>
