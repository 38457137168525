import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserState } from '@core/models/interfaces/company-organization';
import { UserDetail } from '@core/models/interfaces/user';
import { UserService } from '@core/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { OverlayModule } from 'primeng/overlay';

@Component({
  selector: 'app-org-chart-dropdown-option',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OverlayModule,
    CustomAvatarComponent,
    InfiniteScrollDirective,
    SearchInputComponent,
    EmptyTableComponent
  ],
  templateUrl: './org-chart-dropdown-option.component.html',
  styleUrl: './org-chart-dropdown-option.component.scss'
})
export class OrgChartDropdownOptionComponent {
  @Input() visible: boolean = false;
  @Input() appendTo: 'body' | HTMLElement;
  @Input() userState: UserState;
  @Input() userIdsSelected: string[] = [];

  @Output() visibleChange = new EventEmitter<boolean>(false);
  @Output() optionSelected = new EventEmitter();
  @Output() userStateChange = new EventEmitter();

  readonly translatePrefix = 'company-organization.';

  userSelected: UserDetail;

  constructor(private userService: UserService) {}

  fetchUser(key: string) {
    this.userState.loading = true;
    this.userService
      .getUsers({ page: this.userState.page, size: this.userState.size, keyword: this.userState.keyword })
      .subscribe({
        next: res => {
          this.userState = {
            ...this.userState,
            data: key === 'SCROLL' ? [...this.userState.data, ...res?.data?.content] : res?.data?.content,
            loading: false,
            total: res?.data?.total
          };
        }
      });
  }

  onScroll() {
    this.userState.page++;
    this.userStateChange.emit(this.userState);
    if (this.userState.page * this.userState.size < this.userState.total) {
      this.fetchUser('SCROLL');
    }
  }

  onSearch(keyword: string) {
    this.userState.page = 0;
    this.userState.keyword = keyword;
    this.fetchUser('SEARCH');
  }

  onSelectOptionUser(option: UserDetail) {
    this.userSelected = option;
    this.visibleChange.emit(false);
    this.optionSelected.emit(option);
  }

  onHide() {
    this.visibleChange.emit(false);
  }

  mapIdsUserDisable(id: string) {
    const exists = this.userIdsSelected?.includes(id);
    return Boolean(exists);
  }
}
