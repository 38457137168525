import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-org-chart-quick-action',
  standalone: true,
  imports: [],
  templateUrl: './org-chart-quick-action.component.html',
  styleUrl: './org-chart-quick-action.component.scss'
})
export class OrgChartQuickActionComponent {
  @Input() organizationChart: TreeNode[];

  @Output() organizationChartChange = new EventEmitter();

  zoomLevel: number = 100;

  ngOnChanges(): void {}

  fitToScreen() {
    this.zoomLevel = 100;
    this.applyZoom();
  }

  zoomIn() {
    this.zoomLevel += 5;
    this.applyZoom();
  }

  zoomOut() {
    if (this.zoomLevel > 5) {
      this.zoomLevel -= 5;
      this.applyZoom();
    }
  }

  applyZoom() {
    const chartElement = document.querySelector('.p-organizationchart') as HTMLElement;
    if (chartElement) {
      chartElement.style.transform = `scale(${this.zoomLevel / 100})`;
    }
  }
}
