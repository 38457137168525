export enum COLOR {
  BLUE = '#3B82F6',
  CYAN = '#06B6D4',
  PURPLE = '#8B5CF6',
  GREEN = '#22C55E',
  PINK = '#EC4899',
  ROSE = '#F43F5E',
  ORANGE = '#F97316',
  YELLOW = '#FFCC00',
  GRAY = '#B3B9C4',
  RED = '#DC2626'
}

export enum COLOR_BOLD {
  BLUE = '#1D4ED8',
  CYAN = '#0891B2',
  PURPLE = '#6D28D9',
  GREEN = '#15803C',
  PINK = '#BE185D',
  ROSE = '#BE123C',
  ORANGE = '#BB4A02',
  YELLOW = '#A6A602',
  GRAY = '#626F86',
  RED = '#B91C1C'
}

export enum COLOR_LIGHT {
  BLUE = '#93C5FD',
  CYAN = '#67E8F9',
  PURPLE = '#C4B5FD',
  GREEN = '#86EFAC',
  PINK = '#FBCFE8',
  ROSE = '#FDA4AF',
  ORANGE = '#FFC046',
  YELLOW = '#FFECA1',
  GRAY = '#DCDFE4',
  RED = '#FCA5A5'
}
