<div class="flex flex-col gap-4 p-5 border rounded-md">
  <h2 class="font-semibold text-4">{{ title }}</h2>
  <!-- FORM -->
  <form class="flex flex-col gap-5" [formGroup]="performanceForm">
    <!-- OBJECTIVE SELECTION -->
    <div class="flex flex-col gap-2">
      <h3 class="text-2 font-medium uppercase">{{ translatePrefix + 'objective-selection' | translate }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <app-form-control
          [control]="performanceForm.get('users')"
          [floatLabel]="true"
          [label]="translatePrefix + 'user' | translate">
          <p-multiSelect
            input
            [showToggleAll]="false"
            [filter]="true"
            [loading]="isLoading"
            [options]="users"
            optionLabel="fullName"
            optionValue="id"
            formControlName="users"
            dropdownIcon="icon-wl-arrow-drop-down"
            [virtualScroll]="true"
            [virtualScrollItemSize]="36">
            <ng-template let-user pTemplate="item">
              <div class="flex items-center gap-2">
                <app-custom-avatar [name]="user.fullName" [imgId]="user.avatar"></app-custom-avatar>
                <div>{{ user?.fullName }}</div>
              </div>
            </ng-template>

            <ng-template let-value pTemplate="selectedItems">
              <span>{{ getUsersValue(value) || (translatePrefix + 'all-users' | translate) }}</span>
            </ng-template>
          </p-multiSelect>
        </app-form-control>

        <app-form-control
          *ngIf="userGroups?.length"
          [control]="performanceForm.get('userGroups')"
          [floatLabel]="true"
          [label]="translatePrefix + 'user-groups' | translate">
          <p-treeSelect
            input
            [filter]="false"
            [options]="userGroups"
            [loading]="isLoading"
            scrollHeight="360px"
            containerStyleClass="w-full"
            formControlName="userGroups"
            dropdownIcon="icon-wl-arrow-drop-down"
            selectionMode="checkbox">
            <ng-template pTemplate="triggericon">
              <i class="icon-wl-arrow-drop-down text-6 text-neutral-1000 mr-1.5"></i>
            </ng-template>

            <ng-template pTemplate="itemtogglericon" let-implicit>
              <i
                class="text-6 text-neutral-1000"
                [ngClass]="implicit ? 'icon-wl-arrow-drop-down' : 'icon-wl-arrow-drop-right'"></i>
            </ng-template>

            <ng-template let-value pTemplate="value">
              <span>{{ getTreeNodeValue(value) || (translatePrefix + 'all-groups' | translate) }}</span>
            </ng-template>
          </p-treeSelect>
        </app-form-control>
        <!-- </app-form-control> -->
      </div>
    </div>

    <!-- DATE -->
    <div class="flex flex-col gap-2">
      <h3 class="text-2 font-medium uppercase">{{ translatePrefix + 'date' | translate }}</h3>
      <div class="grid grid-cols-3 gap-4">
        <app-custom-calendar
          [isHasAllTime]="true"
          [required]="true"
          [isInput]="true"
          [title]="translatePrefix + 'date' | translate"
          [(datesSelected)]="dates"
          [maxDate]="dateNow"></app-custom-calendar>
      </div>
    </div>
  </form>

  <!-- ACTIONS -->
  <div class="flex items-center gap-2 justify-end">
    @if (usersValue?.length || userGroupsValue?.length || dates.length === 2) {
      <app-button
        variant="ghost-primary"
        [label]="'action.reset' | translate"
        size="sm"
        (onClick)="onReset()"></app-button>
    }

    <app-button
      [label]="translatePrefix + 'run-report' | translate"
      size="sm"
      [disabled]="performanceForm.invalid || dates.length < 2"
      [loading]="isRunning"
      (onClick)="onRunReport()"></app-button>
  </div>
</div>
