import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { FrozenColStyleDirective } from '@shared/directives/frozen-col-style.directive';
import { PerformanceSortFieldEnum } from '@core/enum/report';
import { SortTableHeaderComponent } from '@shared/components/sort-table-header/sort-table-header.component';
import { GetPerformanceParams, PerformanceParams, PerformanceReport } from '@core/models/interfaces/report';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { ReportService } from '@core/services/report.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { MenuModule } from 'primeng/menu';
import { MenuItem, MessageService } from 'primeng/api';
import { downloadFile } from '@shared/utils/dowloadFile.util';
import { NgxPermissionsModule } from 'ngx-permissions';
import { APP_PERMISSION } from '@core/enum/permisson';

@Component({
  selector: 'app-performance-report',
  standalone: true,
  imports: [
    TableModule,
    TranslateModule,
    CommonModule,
    MenuModule,
    NgxPermissionsModule,

    EmptyTableComponent,
    SortTableHeaderComponent,
    FrozenColStyleDirective,
    CustomPaginatorComponent,
    ButtonComponent
  ],
  templateUrl: './performance-report.component.html',
  styleUrl: './performance-report.component.scss'
})
export class PerformanceReportComponent implements OnChanges {
  @Input() total: number = 0;
  @Input() params: PerformanceParams;
  @Input() isRunning: boolean = false;

  @Output() isRunningChange = new EventEmitter<boolean>();

  readonly Permission = APP_PERMISSION;
  readonly translatePrefix = 'report.';
  readonly messagePrefix = 'report.message.';
  readonly PerformanceSortFieldEnum = PerformanceSortFieldEnum;
  readonly exportOptions: MenuItem[] = [
    {
      title: 'CSV',
      img: '/assets/images/icons/file-csv.svg',
      command: () => {
        this.onExportReport('CSV');
      }
    },
    {
      title: 'PDF',
      img: '/assets/images/icons/file-pdf.svg',
      command: () => {
        this.onExportReport('PDF');
      }
    }
  ];

  sortField: string = '';
  sortOrder: number = 0;

  performanceReportDate: Date;
  isLoading: boolean = false;
  performanceReport: PerformanceReport[] = [];
  totalReports: number = 0;
  isFetched: boolean = false;
  isExporting: boolean = false;
  pagination = {
    page: 0,
    size: 10
  };
  options: number[] = [5, 10, 20, 100];

  constructor(
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['params']?.currentValue) {
      this.performanceReportDate = new Date();
      this.pagination = {
        page: 0,
        size: 10
      };
      this.fetchPerformanceReport();
    }
  }

  onSort(event: { field: string; order: number }) {
    if (this.isLoading) return;
    this.sortField = event.field;
    this.sortOrder = event.order;

    this.fetchPerformanceReport();
  }

  get performanceReportParams(): GetPerformanceParams {
    const groupIds = this.params?.groups?.map(item => item.key)?.filter(item => !!item) as string[];
    return {
      ...this.params,
      groupIds: groupIds
    };
  }

  fetchPerformanceReport() {
    const sorts = this.sortField ? (this.sortOrder < 0 ? this.sortField : `-${this.sortField}`) : '';
    const params = this.performanceReportParams;

    this.isLoading = true;
    this.reportService
      .getPerformanceReport({
        ...params,
        page: this.pagination.page,
        size: this.pagination.size,
        sorts
      })
      .subscribe({
        next: res => {
          this.isFetched = true;
          this.isLoading = false;
          this.totalReports = res.data.total;
          this.performanceReport = res.data.content;

          this.isRunning = false;
          this.isRunningChange.emit(this.isRunning);
        },
        error: () => {
          this.isLoading = false;
          this.isRunning = false;
          this.isRunningChange.emit(this.isRunning);
        }
      });
  }

  onPagination() {
    this.fetchPerformanceReport();
  }

  onExportReport(type: 'CSV' | 'PDF') {
    this.isExporting = true;
    this.reportService.exportPerformanceReport(type, { ...this.params }).subscribe({
      next: res => {
        downloadFile(res, 'Performance Report');
        this.isExporting = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'export-performance-report-failed')
        });
      }
    });
  }
}
