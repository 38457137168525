import { createReducer, on } from '@ngrx/store';
import { setTaskState } from './task.actions';

export interface TaskState {
  isEditTask: boolean;
  taskId: string;
  viewMode: 'LIST' | 'APPOINTMENT';
  startDate?: Date;
}

const initialState: TaskState = {
  isEditTask: false,
  taskId: '',
  viewMode: 'LIST'
};

export const taskReducer = createReducer(
  initialState,
  on(setTaskState, (state, { isEditTask, taskId, viewMode, startDate }) => ({
    ...state,
    isEditTask,
    taskId,
    startDate,
    viewMode
  }))
);
