import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi } from '@core/models/interfaces/common';
import {
  OrganizationDetail,
  OrganizationImportBody,
  OrganizationImportDetail
} from '@core/models/interfaces/company-organization';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  baseUrl = `${environment.API_URL}/crm/organization`;

  constructor(private http: HttpClient) {}

  getOrgChart() {
    return this.http.get<ResponseApi<OrganizationDetail[]>>(`${this.baseUrl}/`);
  }

  importToOrgChart(body: OrganizationImportBody) {
    return this.http.post<ResponseApi<OrganizationImportDetail>>(`${this.baseUrl}/imports`, body);
  }

  deleteFromOrgChart(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  updateToOrgChart(body: OrganizationImportBody[]) {
    return this.http.put<ResponseApi<OrganizationImportDetail>>(`${this.baseUrl}/update/parent`, body);
  }
}
