import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ImageBannerSelectedState } from '@core/models/interfaces/admin-content';
import { BannerDetail, CreateBanner } from '@core/models/interfaces/admin-content/admin-content';
import { BannerService } from '@core/services/amin-content/banner.service';
import { StorageService } from '@core/services/storage.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog/custom-dialog.component';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { FileSizePipe } from '@shared/directives/file-size.directive';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-content-banner-edit',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CustomDialogComponent,
    FormControlComponent,
    ButtonComponent,
    InputGroupModule,
    InputTextModule,
    InputGroupAddonModule,
    FileUploadModule,
    FileSizePipe,
    ReactiveFormsModule,
    CustomImageComponent,
    CustomLoadingComponent
  ],
  templateUrl: './admin-content-banner-edit.component.html',
  styleUrl: './admin-content-banner-edit.component.scss'
})
export class AdminContentBannerEditComponent {
  @Input() visible: boolean = false;
  @Input() bannerDetail?: BannerDetail;

  @Output() visibleChange = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();

  bannerTitleControl: FormControl;
  loading: boolean = false;
  imageSelect: ImageBannerSelectedState = {
    imageWidth: 0,
    imageHeight: 0,
    imageName: undefined,
    imageSize: 0,
    imageUrl: undefined,
    loading: false,
    originFile: undefined
  };

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private bannerService: BannerService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnChanges(): void {
    this.initForm(this.bannerDetail);
  }

  initForm(data?: BannerDetail) {
    this.bannerTitleControl = this.fb.control(data?.title ?? null);
  }

  onCancel() {
    if (!this.loading) {
      this.visibleChange.emit(false);
      this.resetFileSelection();
    }
  }

  onChangeFile(event: Event) {
    this.imageSelect.loading = true;
    const target = event.target as HTMLInputElement;
    const file = target?.files?.[0];
    this.imageSelect.originFile = file;
    this.imageSelect.imageName = file?.name ?? '';
    this.imageSelect.imageSize = file?.size ?? 0;

    const reader = new FileReader();
    reader.readAsDataURL(file as Blob);

    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      this.imageSelect.imageUrl = reader.result as string;

      img.onload = () => {
        this.imageSelect.imageWidth = img.width;
        this.imageSelect.imageHeight = img.height;
        this.imageSelect.loading = false;
      };
    };
  }

  onSaveBanner() {
    this.loading = true;
    const uploadSource: Observable<any> = this.imageSelect.imageName
      ? this.storageService.uploadFile(this.imageSelect.originFile as File)
      : of(null);

    uploadSource
      .pipe(
        switchMap(res => {
          const body: CreateBanner = {
            title: this.bannerTitleControl.value,
            location: this.bannerDetail?.location ?? '',
            attachment: res?.data?.id ?? this.bannerDetail?.attachment
          };

          return this.bannerDetail?.id ? this.bannerService.updateBanner(this.bannerDetail?.id, body) : of(null);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.loading = false;
          this.onSuccess.emit();
          this.onCancel();

          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant(this.translatePrefix + 'update-banner-success')
          });
          this.resetFileSelection();
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            detail: this.translateService.instant(this.translatePrefix + 'update-banner-fail')
          });
        }
      });
  }

  resetFileSelection() {
    this.imageSelect = {
      imageWidth: 0,
      imageHeight: 0,
      imageName: undefined,
      imageSize: 0,
      imageUrl: undefined,
      loading: false,
      originFile: undefined
    };
    const uploadFileInput = document.getElementById('uploadFile') as HTMLInputElement;
    if (uploadFileInput) {
      uploadFileInput.value = '';
    }
  }

  getRecommendedSize(): string {
    const sizeMap: { [key: string]: string } = {
      Home: 'recommended-size-home',
      'Sign in': 'recommended-size-sign-in',
      Logo: 'recommended-size-logo',
      Cover: 'recommended-size-cover',
      Profile: 'recommended-size-profile'
    };

    const locationKey = this.bannerDetail?.location || ''; // Thêm giá trị mặc định nếu undefined
    const translationKey = sizeMap[locationKey] || '';

    return translationKey ? this.translateService.instant(this.translatePrefix + translationKey) : '';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
