import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as d3 from 'd3';

export interface SunburstNodeType {
  name: string;
  color?: string;
  children?: SunburstNodeType[];
  value?: number;
}

@Component({
  selector: 'sunburst-chart',
  templateUrl: './sunburst-chart.component.html',
  styleUrls: ['./sunburst-chart.component.scss'],
  standalone: true
})
export class SunburstChartComponent implements AfterViewInit {
  @ViewChild('chartContainer') chartContainer: ElementRef<HTMLElement>;
  @Input() data: SunburstNodeType;

  width = 640;
  radius = 320;
  colorMap = d3.schemePaired;

  arc: any = d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .padAngle(1 / this.radius)
    .padRadius(this.radius)
    .innerRadius((d: any) => Math.sqrt(d.y0))
    .outerRadius((d: any) => Math.sqrt(d.y1) - 1);

  mousearc: any = d3
    .arc()
    .startAngle((d: any) => d.x0)
    .endAngle((d: any) => d.x1)
    .innerRadius((d: any) => Math.sqrt(d.y0))
    .outerRadius(this.radius);

  partition = (data: any) =>
    d3.partition().size([2 * Math.PI, this.radius * this.radius])(
      d3
        .hierarchy(data)
        .sum(d => d.value)
        .sort((a: any, b: any) => b.value - a.value)
    );

  constructor() {}

  ngAfterViewInit() {
    this.createChart();
  }

  ngOnChanges() {
    if (this.data && this.chartContainer) {
      this.clearChart();
      this.createChart();
    }
  }

  clearChart() {
    // Remove any existing SVG elements within the chartContainer
    const containerElement = this.chartContainer.nativeElement;
    while (containerElement.firstChild) {
      containerElement.removeChild(containerElement.firstChild);
    }
  }

  createChart() {
    const root: any = this.partition(this.data);
    const svg = d3.create('svg');
    const element: any = svg.node();

    const label = svg.append('text').attr('text-anchor', 'middle').attr('fill', '#888').style('visibility', 'hidden');

    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'sunburst-tooltip')
      .style('position', 'absolute')
      .style('display', 'none')
      .style('background', 'rgba(0, 0, 0, 0.7)')
      .style('color', '#fff')
      .style('padding', '5px 10px')
      .style('border-radius', '4px')
      .style('font-size', '12px');

    label
      .append('tspan')
      .attr('class', 'percentage')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dy', '-0.1em')
      .attr('font-size', '24px')
      .text('');

    label
      .append('tspan')
      .attr('class', 'inner-label')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dy', '1.5em')
      .text('of visits begin with this sequence');

    svg
      .attr('viewBox', `${-this.radius} ${-this.radius} ${this.width} ${this.width}`)
      .style('max-this.width', `${this.width}px`)
      .style('font', '24px Roboto-Medium');

    const path = svg
      .append('g')
      .selectAll('path')
      .data(
        root.descendants().filter((d: any) => {
          // Don't draw the root node, and for efficiency, filter out nodes that would be too small to see
          return d.depth && d.x1 - d.x0 > 0.001;
        })
      )
      .join('path')
      .attr('fill', (d: any) => d.data.color)
      .attr('d', this.arc);
    svg
      .append('g')
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseleave', () => {
        path.attr('fill-opacity', 1);
        tooltip.style('display', 'none');
      })
      .selectAll('path')
      .data(
        root.descendants().filter((d: any) => {
          return d.depth && d.x1 - d.x0 > 0.001;
        })
      )
      .join('path')
      .attr('d', this.mousearc)
      .on('mouseenter', (event, d: any) => {
        const total = root.value;
        const percentage = ((d.value / total) * 100).toFixed(2);

        const sequence = d.ancestors().reverse().slice(1);
        path.attr('fill-opacity', node => (sequence.indexOf(node) >= 0 ? 1.0 : 0.5));

        const tooltipText = `${d.data.name}: ${d.value} (${percentage}%)`;

        tooltip
          .style('display', 'block')
          .html(tooltipText)
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mousemove', event => {
        tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 20}px`);
      })
      .on('mouseleave', () => {
        tooltip.style('display', 'none');
        path.attr('fill-opacity', 1);
      });

    this.chartContainer.nativeElement.appendChild(element);
  }
}
