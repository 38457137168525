import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseListApi } from '@core/models/interfaces/common';
import { Notifications } from '@core/models/interfaces/notification/notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private baseUrl = `${environment.API_URL}/crm/notifications`;

  constructor(private httpClient: HttpClient) {}

  getNotificationsForMe(page: number, size: number, unreadOnly: boolean = false) {
    let queryParams = new HttpParams()
      .set('unreadOnly', unreadOnly.toString())
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sort', '-createdAt');
    return this.httpClient.get<ResponseListApi<Notifications>>(`${this.baseUrl}/me`, {
      params: queryParams
    });
  }

  markAllAsRead() {
    return this.httpClient.put(`${this.baseUrl}/all-read`, '');
  }

  readNotification(id: string) {
    return this.httpClient.put(`${this.baseUrl}/${id}/read`, '');
  }
}
