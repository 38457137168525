import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Notifications } from '@core/models/interfaces/notification/notifications';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NotificationsService } from '@core/services/notifications.service';
import { TimeAgoPipe } from '@shared/pipes/time-ago.pipe';
import { CustomAvatarComponent } from '../custom-avatar/custom-avatar.component';
import { Router } from '@angular/router';
import { FormatDatePipe } from '@shared/pipes/format-date.pipe';
import { CommentService } from '@core/services/comment.service';
import { catchError, of, Subject, switchMap, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonComponent } from '../button/button.component';
import { AvatarModule } from 'primeng/avatar';
import { CommentDetails } from '@core/models/interfaces/comment';
import { CustomLoadingComponent } from '../custom-loading/custom-loading.component';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    OverlayPanelModule,
    CommonModule,
    FormatDatePipe,
    BadgeModule,
    ScrollPanelModule,
    TimeAgoPipe,
    CustomAvatarComponent,
    TranslateModule,
    DialogModule,
    ButtonComponent,
    AvatarModule,
    CustomLoadingComponent
  ],

  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  readonly translatePrefix = 'notification.';
  readonly unsubscribe$ = new Subject();

  notifications: Notifications[] = [];
  objectId: string;
  objectType: string;
  visible: boolean = false;
  comments: CommentDetails[] = [];
  isLoading: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private router: Router,
    private commentService: CommentService
  ) {}

  @ViewChild('op') overlayPanel!: OverlayPanel;
  activeSubmenu: boolean = false;
  isMouseInsideTagLi: boolean = false;
  isMouseInsideSubmenu: boolean = false;
  submenuPosition = { x: 0, y: 0 };
  debounceTimer: any;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.overlayPanel && this.overlayPanel.overlayVisible) {
      this.overlayPanel.hide();
      this.hideSubmenu();
    }
  }

  ngOnInit(): void {
    this.fetchNotifications();
  }

  showSubmenu(event: MouseEvent): void {
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    this.submenuPosition.x = rect.left - 400;
    this.submenuPosition.y = rect.bottom - rect.height;
    this.activeSubmenu = true;
  }

  onMouseEnterLi(notificationType: string, objectId: string, event: MouseEvent): void {
    this.hideSubmenu();
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      this.onFetchComments(notificationType, objectId, event);
    }, 300);
    this.isMouseInsideTagLi = true;
  }

  onMouseLeaveLi(): void {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.isMouseInsideTagLi = false;
    setTimeout(() => {
      this.checkToHideSubmenu();
    }, 0);
  }

  onMouseEnterSubmenu(): void {
    this.isMouseInsideSubmenu = true;
  }

  onMouseLeaveSubmenu(): void {
    this.isMouseInsideSubmenu = false;
    this.checkToHideSubmenu();
  }

  checkToHideSubmenu(): void {
    if (!this.isMouseInsideTagLi && !this.isMouseInsideSubmenu) {
      this.hideSubmenu();
    }
  }

  hideSubmenu(): void {
    this.activeSubmenu = false;
  }

  get isUnRead() {
    return this.notifications.flatMap(item => item.notifications).filter(item => item.unread).length > 0;
  }

  fetchNotifications() {
    this.notificationsService
      .getNotificationsForMe(0, 50)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.notifications = res.data.content;
      });
  }

  onNavigateToComments(objectId: string, id: string, path: string, op: OverlayPanel) {
    this.commentService
      .getCommentById(objectId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.objectType = res.data.objectType;
          this.onReadNotification(id);
          switch (this.objectType) {
            case 'LEAD':
              this.router.navigate(['lead-management', 'lead-details', path, 'overview']);
              break;

            case 'TASK':
              this.router.navigate(['lead-management', 'lead-details', path, 'tasks']);
              break;

            case 'APPOINTMENT':
              this.router.navigate(['lead-management', 'lead-details', path, 'tasks'], {
                queryParams: { modeView: 'appointment' }
              });
              break;

            case 'POLICY':
              this.router.navigate(['lead-management', 'lead-details', path, 'policies']);
              break;
          }
          this.hideSubmenu();
          op.hide();
        }
      });
  }

  onFetchComments(notificationType: string, objectId: string, event: MouseEvent) {
    if (notificationType === 'COMMENT') {
      this.visible = true;

      this.commentService
        .getCommentById(objectId)
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap(res => {
            this.objectType = res.data.objectType;
            this.objectId = res.data.objectId;

            return this.commentService.getComments({
              objectId: this.objectId,
              sorts: '-isPin|-createdAt'
            });
          })
        )
        .subscribe({
          next: res => {
            this.comments = res.data.content;
            this.showSubmenu(event);
            this.isLoading = false;
          }
        });
    }
  }

  onNavigateToCommentNote(id: string, path: string, op: OverlayPanel) {
    this.onReadNotification(id);
    this.objectId = path;
    this.router.navigate(['lead-management', 'lead-details', this.objectId, 'notes']);
    op.hide();
  }

  onNavigateToTask(notificationType: string, id: string, op: OverlayPanel) {
    this.onReadNotification(id);
    if (notificationType === 'TASK') {
      this.router.navigate(['tasks']);
    } else {
      this.router.navigate(['tasks'], {
        queryParams: { modeView: 'appointment' }
      });
    }
    op.hide();
  }

  onNavigateToHomepPage(id: string, op: OverlayPanel) {
    this.onReadNotification(id);
    this.router.navigate(['home']);
    op.hide();
  }

  onReadNotification(id: string) {
    this.notificationsService.readNotification(id).subscribe({
      next: () => {
        this.fetchNotifications();
      }
    });
  }

  onMaskAllAsRead() {
    this.notificationsService.markAllAsRead().subscribe({
      next: () => {
        this.fetchNotifications();
      }
    });
  }

  onNavigate(notificationType: string, objectId: string, path: string, id: string, op: OverlayPanel) {
    switch (notificationType) {
      case 'COMMENT':
        this.onNavigateToComments(objectId, id, path, op);
        break;

      case 'NOTE':
        this.onNavigateToCommentNote(id, path, op);
        break;

      case 'POST':
      case 'LINK':
        this.onNavigateToHomepPage(id, op);
        break;

      case 'TASK':
      case 'APPOINTMENT':
        this.onNavigateToTask(notificationType, id, op);
        break;

      default:
        this.onReadNotification(id);
        break;
    }
  }
}
