import { APP_PERMISSION } from '@core/enum/permisson';
import { ReportTabEnum } from '@core/enum/report';
import { KeyedValue } from '@core/models/interfaces/common';

export const ProductDetailsGroupBys: KeyedValue[] = [
  {
    title: 'All',
    value: 'ALL'
  },
  {
    title: 'Owner',
    value: 'OWNER'
  },
  {
    title: 'Agent',
    value: 'AGENT'
  },
  {
    title: 'Company',
    value: 'COMPANY'
  },
  {
    title: 'Product type',
    value: 'PRODUCT_TYPE'
  },
  {
    title: 'Product name',
    value: 'PRODUCT_NAME'
  },
  {
    title: 'Source',
    value: 'SOURCE'
  },
  {
    title: 'Sub source',
    value: 'SUB_SOURCE'
  },
  {
    title: 'Policy status',
    value: 'POLICY_STATUS'
  },
  {
    title: 'Policy status details',
    value: 'POLICY_STATUS_DETAIL'
  },
  {
    title: 'Commission owner',
    value: 'COMMISSION_OWNER'
  },
  {
    title: 'Commission agent',
    value: 'COMMISSION_AGENT'
  },
  {
    title: 'Commission team',
    value: 'COMMISSION_TEAM'
  }
];

export const ReportTabOptions = [
  {
    title: 'production',
    value: ReportTabEnum.PRODUCT,
    order: 1,
    permission: APP_PERMISSION.REPORT_PRODUCTION_DETAILS_RUN
  },
  {
    title: 'performance',
    value: ReportTabEnum.PERFORMANCE,
    order: 2,
    permission: APP_PERMISSION.REPORT_PERFORMANCE_RUN
  },
  {
    title: 'lead',
    value: ReportTabEnum.LEAD,
    order: 3,
    permission: APP_PERMISSION.REPORT_LEAD_RUN
  }
];
