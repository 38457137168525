import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseApi } from '@core/models/interfaces/common';
import {
  NotificationSetting,
  NotificationSettingContent,
  NotificationSettingUpdate
} from '@core/models/interfaces/notification/notification-setting';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationSettingService {
  private baseUrl = `${environment.API_URL}/crm/notification-settings`;

  constructor(private httpClient: HttpClient) {}

  getNotificationSettings(pageNum: number, pageSize: number, sort: string = 'code') {
    return this.httpClient.get<ResponseApi<NotificationSettingContent>>(
      `${this.baseUrl}?page=${pageNum}&size=${pageSize}&sort=${sort}`
    );
  }

  updateNotificationSetting(id: string, dto: NotificationSettingUpdate) {
    return this.httpClient.put<ResponseApi<NotificationSetting>>(`${this.baseUrl}/${id}`, dto);
  }
}
