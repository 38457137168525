<div class="flex flex-col gap-4 px-8">
  <div class="flex items-center gap-3 justify-between">
    <app-search-input inputClass="h-8 w-full" [debounceTime]="600" (onSearch)="onSearch($event)"></app-search-input>
    <app-button
      *ngxPermissionsOnly="Permissions.POST_CREATE_EDIT"
      icon="icon-wl-plus-circle"
      iconPosition="left"
      [label]="'action.create' | translate"
      size="sm"
      (onClick)="onCreatePost()"></app-button>
  </div>

  <!-- Table -->
  <p-table
    (onSort)="onSort($event)"
    [lazy]="true"
    [customSort]="true"
    [value]="postManagementState.posts"
    [scrollable]="true"
    [loading]="postManagementState.loading">
    <ng-template pTemplate="header">
      <tr>
        <th
          class="min-w-7"
          pFrozenColumn
          [frozen]="true"
          alignFrozen="left"
          [pSortableColumn]="AdminContentSortFieldEnum.TITLE">
          <app-sort-table-header
            [label]="translatePrefix + 'title' | translate"
            [field]="AdminContentSortFieldEnum.TITLE"
            [sortField]="sortField"
            [sortOrder]="sortOrder"></app-sort-table-header>
        </th>
        <th>{{ translatePrefix + 'description' | translate }}</th>
        <th>{{ translatePrefix + 'attachment' | translate }}</th>
        <th
          class="min-w-7"
          pFrozenColumn
          [frozen]="true"
          alignFrozen="left"
          [pSortableColumn]="AdminContentSortFieldEnum.CREATED_BY">
          <app-sort-table-header
            [label]="translatePrefix + 'created-by' | translate"
            [field]="AdminContentSortFieldEnum.CREATED_BY"
            [sortField]="sortField"
            [sortOrder]="sortOrder"></app-sort-table-header>
        </th>
        <th
          class="min-w-7"
          pFrozenColumn
          [frozen]="true"
          alignFrozen="left"
          [pSortableColumn]="AdminContentSortFieldEnum.CREATED_AT">
          <app-sort-table-header
            [label]="translatePrefix + 'created-date' | translate"
            [field]="AdminContentSortFieldEnum.CREATED_AT"
            [sortField]="sortField"
            [sortOrder]="sortOrder"></app-sort-table-header>
        </th>
        <th class="min-w-7" pFrozenColumn [frozen]="true" alignFrozen="right"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="loadingbody">
      <tr class="h-[128px]">
        <td class="!px-3" [colSpan]="6"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-post>
      <tr>
        <!-- Title -->
        <td class="min-w-7" pFrozenColumn [frozen]="true" alignFrozen="left">
          <div class="flex items-center gap-2 max-w-[480px] truncate">
            <span class="flex-1 truncate">{{ post.title ?? '--' }}</span>
            <div class="w-6 h-6">
              @if (post?.isPin || totalPins < 3) {
                <div
                  *ngxPermissionsOnly="Permissions.POST_PIN"
                  (click)="onPinPost(post)"
                  [pTooltip]="translatePrefix + (!post?.isPin ? 'pin' : 'un-pin') | translate"
                  tooltipPosition="top"
                  class="w-full h-full cursor-pointer hover:!opacity-100 flex justify-center items-center shadow-sm hover:border hover:border-neutral-300 hover:bg-neutral-200 rounded">
                  @if (post?.isPin) {
                    <img src="assets/images/icons/icon-pin-solid-black.svg" alt="pin" class="w-4 h-4 leading-6" />
                  } @else {
                    <i class="icon-wl-pin text-4"></i>
                  }
                </div>
              }
            </div>
          </div>
        </td>

        <!-- Description -->
        <td class="max-w-[480px]">
          <span class="line-clamp-1" [innerHTML]="domSanitizer(post.description)"></span>
        </td>

        <!-- Attachment -->
        <td>
          @if (post?.attachments?.length > 0) {
            <div class="flex items-center gap-2">
              <app-custom-image
                [imgId]="post?.attachments?.[0]"
                alt="img"
                imageClass="!h-10 !w-10 rounded object-cover"></app-custom-image>
              @if (post?.attachments?.length > 1) {
                <span>+{{ post?.attachments?.length - 1 }}</span>
              }
            </div>
          }
        </td>

        <!-- Created by -->
        <td>
          <div class="flex gap-2 items-center">
            <app-custom-avatar
              [imgId]="post?.createdBy?.avatar"
              [name]="post?.createdBy?.fullName ?? ''"></app-custom-avatar>

            <span class="truncate">{{ post?.createdBy?.fullName ?? '--' }}</span>
          </div>
        </td>

        <!-- Created date -->
        <td>
          <span class="truncate">{{ post?.createdAt | date: 'MM/dd/yyyy HH:mm' }}</span>
        </td>

        <!-- Action -->
        <td class="min-w-7 !p-0" pFrozenColumn [frozen]="true" alignFrozen="right">
          <div class="flex items-center px-2">
            <div
              *ngxPermissionsOnly="Permissions.POST_CREATE_EDIT"
              (click)="onEditPost(post)"
              class="flex justify-center items-center w-9 h-9 hover:bg-neutral-200 hover:border hover:border-neutral-300 rounded cursor-pointer">
              <i class="icon-wl-edit text-6"></i>
            </div>
            <div
              *ngxPermissionsOnly="Permissions.POST_DELETE"
              (click)="onDeletePost(post)"
              class="flex justify-center items-center w-9 h-9 hover:bg-neutral-200 hover:border hover:border-neutral-300 rounded cursor-pointer">
              <i class="icon-wl-delete text-6 text-red-500"></i>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="8">
          <app-empty-table />
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="py-3 px-8">
  <app-custom-paginator
    [(pagination)]="pagination"
    [totalRecords]="postManagementState.totalPost"
    (onChange)="fetchPostsManagement()"></app-custom-paginator>
</div>

<app-admin-content-post-edit
  [(visible)]="isVisibleCreatePost"
  [postDetail]="postDetail"
  (onSuccess)="onSuccess()"
  (visibleChange)="onCloseModal()"></app-admin-content-post-edit>
