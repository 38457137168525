<form [formGroup]="groupForm">
  <div class="flex flex-col px-8 py-5 gap-5">
    <span class="font-semibold text-lg text-neutral-950">{{
      translatePrefix + 'notification-settings' | translate
    }}</span>
    <p-table
      (onSort)="onSort($event)"
      [customSort]="true"
      [lazy]="true"
      [value]="settings"
      [tableStyle]="{ 'min-width': '50rem' }"
      [loading]="loading"
      [styleClass]="!settings.length ? 'min-h-[200px]' : ''">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="NotificationsSortFieldEnum.CODE">
            <app-sort-table-header
              [label]="translatePrefix + 'notification-type' | translate"
              [field]="NotificationsSortFieldEnum.CODE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
          <th>{{ translatePrefix + 'permission-groups' | translate }}</th>
          <th class="max-w-[88px]"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-setting>
        <tr>
          <td class="truncate">{{ setting.name }}</td>
          <td>
            {{ getFormattedGroupNames(setting) }}
          </td>
          <td class="min-w-7 !p-0" pFrozenColumn [frozen]="true" alignFrozen="right">
            <div class="flex items-center justify-end px-2">
              <div
                *ngxPermissionsOnly="Permissions.NOTIFICATION_EDIT"
                class="flex w-9 h-9 hover:bg-neutral-200 border border-transparent hover:border-neutral-300 rounded justify-center cursor-pointer"
                (click)="showDialog(setting)">
                <i class="icon-wl-edit text-6 text-primary-600"></i>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="3">
            <app-empty-table />
          </td>
        </tr>
      </ng-template>
    </p-table>
    @if (notificationSettingSelected) {
      <p-dialog
        [draggable]="false"
        [header]="'action.edit' | translate"
        [(visible)]="visible"
        [modal]="true"
        [style]="{ width: '640px' }">
        <div class="flex flex-col gap-4">
          <div
            class="px-3 flex flex-col py-1.5 justify-between border bg-neutral-alpha-500A/5 h-12 rounded-md border-neutral-alpha-500A/10">
            <span class="text-2 text-neutral-alpha-500A/50">{{
              translatePrefix + 'notification-type' | translate
            }}</span>
            <span>{{ notificationSettingSelected.name }}</span>
          </div>
          <app-form-control
            [label]="translatePrefix + 'permission-groups' | translate"
            [floatLabel]="true"
            [control]="permissionGroupControl">
            <p-treeSelect
              input
              [showClear]="true"
              appendTo="body"
              containerStyleClass="w-full"
              [options]="treeGroupsPermission"
              display="chip"
              formControlName="permissionGroups"
              selectionMode="checkbox"
              [propagateSelectionDown]="false"
              [propagateSelectionUp]="false"
              [virtualScroll]="true"
              [virtualScrollItemSize]="46"
              [virtualScrollOptions]="{ scrollHeight: '150px' }">
              <ng-template pTemplate="triggericon">
                <i class="icon-wl-arrow-drop-down text-6 text-neutral-1000 mr-1.5"></i>
              </ng-template>

              <ng-template pTemplate="itemtogglericon" let-implicit>
                <i
                  class="text-6 text-neutral-1000"
                  [ngClass]="implicit ? 'icon-wl-arrow-drop-down' : 'icon-wl-arrow-drop-right'"></i>
              </ng-template>

              <ng-template pTemplate="header">
                <div class="flex gap-2 h-9 text-center pl-3 pt-6 pb-6 border-[1px]">
                  <p-checkbox
                    binary="true"
                    [(ngModel)]="isSelectedAllGroup"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="selectedAll()" />
                  <label for="all" class="self-center text-3 text-neutral-1000">All</label>
                </div>
              </ng-template>

              <ng-template pTemplate="value">
                <div>
                  <ul class="flex gap-2">
                    @for (group of groupsPermission; track group?.key) {
                      <li class="flex items-center justify-center px-3 rounded-md bg-gray-200 gap-0.5">
                        <span class="max-w-[60px] text-neutral-800 font-medium truncate">{{ group?.label }}</span>
                        <span
                          class="icon-wl-close text-neutral-600"
                          (click)="onRemove($event, group?.key, 'permissionGroup')"></span>
                      </li>
                    }
                  </ul>
                </div>
              </ng-template>
            </p-treeSelect>
          </app-form-control>
        </div>
        <div class="flex justify-end gap-2 pt-6">
          <app-button
            [label]="'action.cancel' | translate"
            variant="outline-secondary"
            size="xl"
            (onClick)="visible = false" />
          <app-button [label]="'action.save' | translate" (onClick)="onSave()" size="xl" [loading]="isLoadingButton" />
        </div>
      </p-dialog>
    }
  </div>
</form>
