import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NodeLevel, ViewOrganization } from '@core/enum/company-organization';
import { OrganizationCard, UserState } from '@core/models/interfaces/company-organization';
import { UserDetail } from '@core/models/interfaces/user';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { TreeNode } from 'primeng/api';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { OrgChartDropdownOptionComponent } from '../org-chart-dropdown-option/org-chart-dropdown-option.component';

@Component({
  selector: 'app-org-chart-add',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OrgChartDropdownOptionComponent,
    CheckboxModule,
    CustomAvatarComponent,
    FormsModule
  ],
  templateUrl: './org-chart-add.component.html',
  styleUrl: './org-chart-add.component.scss'
})
export class OrgChartAddComponent {
  @Input() visibleNewNodeItem: boolean = false;
  @Input() parentId: string;
  @Input() nodeItem: OrganizationCard;
  @Input() userList: UserDetail[] = [];
  @Input() totalUser: number = 0;
  @Input() viewChart: ViewOrganization = ViewOrganization.VIEW;
  @Input() chartList: TreeNode<OrganizationCard>[];

  @Output() viewChartChange = new EventEmitter();
  @Output() visibleNewNodeItemChange = new EventEmitter();
  @Output() actionNewNodeItem = new EventEmitter();
  @Output() touchedNewNodeItem = new EventEmitter();
  @Output() updateNewNodeItem = new EventEmitter();

  readonly translatePrefix = 'company-organization.';
  readonly ViewOrganization = ViewOrganization;
  readonly NodeLevel = NodeLevel;

  isVisible: boolean = false;
  isFirstClickIgnored: boolean = false;
  optionSelected: UserDetail;

  userState: UserState = {
    data: [],
    loading: false,
    total: 0,
    keyword: '',
    page: 0,
    size: 10
  };
  idsUserSelected: string[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnChanges(): void {
    if (this.userList?.length) {
      this.userState.data = this.userList;
      this.userState.total = this.totalUser;
    }
  }

  onClickNewNodeItem() {
    if (!this.nodeItem.id && this.nodeItem.isHiring) {
      this.nodeItem.id = crypto.randomUUID();
    }
    this.touchedNewNodeItem.emit(this.nodeItem);
    if (this.viewChart === ViewOrganization.EDIT) {
      this.idsUserSelected = this.getAllUniqueUserIds(this.chartList);
      if (!this.nodeItem.isHiring) {
        this.isVisible = true;
      }
    }
  }

  getAllUniqueUserIds(nodes: TreeNode<OrganizationCard>[]): string[] {
    let userIds: string[] = [];

    this.collectUserIds(nodes, userIds);
    return userIds;
  }

  collectUserIds(items: TreeNode<OrganizationCard>[], userIds: string[]): void {
    items.forEach(item => {
      if (item.data && item.data.id) {
        userIds.push(item.data.id);
      }

      if (item.children && item.children.length > 0) {
        this.collectUserIds(item.children, userIds);
      }
    });
  }

  onRemoveNodeItem(nodeItem: OrganizationCard) {
    this.visibleNewNodeItem = !this.visibleNewNodeItem;
    this.visibleNewNodeItemChange.emit(this.visibleNewNodeItem);
    this.actionNewNodeItem.emit({ node: NodeLevel.NONE, nodeItem: nodeItem });
  }

  onSelectedOption(value: UserDetail) {
    this.updateNewNodeItem.emit({
      node: NodeLevel.SAME_LEVEL,
      nodeItem: { ...this.nodeItem, ...value, isInvalid: false },
      action: NodeLevel.NEW
    });
    this.optionSelected = value;
  }

  onActionAddNewNodeItem(node: NodeLevel) {
    this.actionNewNodeItem.emit({ node: node, nodeItem: this.nodeItem, action: NodeLevel.NEW });
  }

  onFocusHiring(event: Event) {
    event.stopPropagation();
    this.isVisible = false;
  }

  isChecked(event: CheckboxChangeEvent) {
    event.originalEvent?.stopPropagation();
    if (event.checked) {
      this.onClickNewNodeItem();
      this.isVisible = false;
      this.updateNewNodeItem.emit({
        node: NodeLevel.SAME_LEVEL,
        nodeItem: {
          ...this.nodeItem,
          isHiring: event.checked,
          id: this.nodeItem.id || crypto.randomUUID()
        },
        action: NodeLevel.UPDATE
      });
    }
  }
}
