import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUpload } from '@core/models/interfaces/attachment';
import { CreateNewFeed, NewFeedDetail } from '@core/models/interfaces/home-page';
import { NewFeedService } from '@core/services/amin-content/new-feed.service';
import { StorageService } from '@core/services/storage.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog/custom-dialog.component';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { UploadFileComponent } from '@shared/components/upload-file/upload-file.component';
import { MessageService } from 'primeng/api';
import { EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { catchError, forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-content-post-edit',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    InputTextModule,
    EditorModule,
    ButtonComponent,
    CustomDialogComponent,
    FormControlComponent,
    UploadFileComponent,
    ReactiveFormsModule,
    ProgressBarModule,
    FloatLabelModule,
    FormsModule,
    CustomImageComponent,
    CustomLoadingComponent
  ],
  templateUrl: './admin-content-post-edit.component.html',
  styleUrl: './admin-content-post-edit.component.scss'
})
export class AdminContentPostEditComponent {
  @Input({ required: true }) visible: boolean = false;
  @Input() postDetail?: NewFeedDetail;

  @Output() visibleChange = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  readonly translatePrefix = 'admin-content.';
  readonly unsubscribe$ = new Subject();

  fileList: FileUpload[] = [];
  createPostForm: FormGroup;
  isLoading: boolean = false;

  postCreate: { title: string; description: string; attachments: string[] } = {
    title: '',
    description: '',
    attachments: []
  };

  isDescriptionEmpty: boolean = true;

  stepLoadingNewFeed: { loadingUpload: boolean; loadingSuccess: boolean } = {
    loadingUpload: false,
    loadingSuccess: false
  };

  constructor(
    private storageService: StorageService,
    private newFeedService: NewFeedService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.visible) {
      this.initData(this.postDetail);
    }
  }

  initData(data?: NewFeedDetail) {
    this.postCreate = {
      title: data?.title ?? '',
      description: data?.description ?? '',
      attachments: data?.attachments ?? []
    };

    this.isDescriptionEmpty = !data?.description.length;
  }

  onCancel() {
    this.visibleChange.emit(false);
    this.fileList = [];
  }

  onUploadFile(event: FileList) {
    this.isLoading = true;
    let validFiles = 0;
    Array.from(event).forEach(file => {
      if (file.size / (1024 * 1024) > 2) {
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant(this.translatePrefix + 'title-required-upload-image', {
            value: file.name
          }),
          detail: this.translateService.instant(this.translatePrefix + 'required-upload-image')
        });
        return;
      }
      validFiles++;
      const reader = new FileReader();
      const fileUpload: FileUpload = {
        id: crypto.randomUUID(),
        file,
        progress: 0,
        status: 'UPLOADING',
        originFile: undefined
      };

      reader.onload = e => {
        setTimeout(() => {
          this.isLoading = false;
          fileUpload.originFile = e.target?.result as string;
          this.fileList = [...this.fileList, fileUpload];
          if (--validFiles === 0) {
            this.isLoading = false;
          }
        }, 200);
      };

      reader.readAsDataURL(file);
    });
    if (validFiles === 0) {
      this.isLoading = false;
    }
  }

  onRemoveImage(id: string) {
    this.fileList = this.fileList.filter(el => el.id !== id);
  }

  onSavePost() {
    if (!this.postCreate.title.length || !this.postCreate?.description?.length) return;
    this.stepLoadingNewFeed = {
      loadingUpload: true,
      loadingSuccess: true
    };

    const source = this.fileList.map(el =>
      this.storageService.uploadFile(el.file).pipe(
        catchError(error => {
          return of(null);
        })
      )
    );
    const uploadSource = source.length ? forkJoin(source) : of([]);
    uploadSource
      .pipe(
        switchMap(res => {
          this.stepLoadingNewFeed.loadingUpload = false;
          const successfulUploads = res.filter(item => item !== null);
          const fileIds = successfulUploads?.map(el => el?.data?.id) as string[];
          this.postCreate.attachments = this.postCreate?.attachments?.concat(fileIds);
          const body: CreateNewFeed = {
            title: this.postCreate?.title ?? '',
            description: this.postCreate?.description ?? '',
            attachments: this.postCreate.attachments
          };

          return this.postDetail?.id
            ? this.newFeedService.updateNewFeed(this.postDetail?.id, body)
            : this.newFeedService.createNewFeed(body);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.stepLoadingNewFeed.loadingSuccess = false;
          this.visibleChange.emit(false);
          this.onSuccess.emit();
          if (this.postDetail?.id) {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'update-post-success')
            });
          } else {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant(this.translatePrefix + 'create-post-success')
            });
          }
        },
        error: () => {
          this.stepLoadingNewFeed.loadingSuccess = false;
          if (this.postDetail?.id) {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant(this.translatePrefix + 'update-post-fail')
            });
          } else {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant(this.translatePrefix + 'create-post-fail')
            });
          }
        }
      });
  }

  onRemoveImageGet(img: string) {
    this.postCreate.attachments = this.postCreate.attachments.filter(el => el !== img);
  }

  onDescriptionChange(event: EditorTextChangeEvent) {
    if (event.textValue.length > 0) {
      this.isDescriptionEmpty = false;
    } else {
      this.isDescriptionEmpty = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
