import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserDetail } from '@core/models/interfaces/user';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { CustomImageComponent } from '@shared/components/custom-image/custom-image.component';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'app-org-chart-detail-node',
  standalone: true,
  imports: [CommonModule, TranslateModule, SidebarModule, CustomImageComponent, CustomAvatarComponent],
  templateUrl: './org-chart-detail-node.component.html',
  styleUrl: './org-chart-detail-node.component.scss'
})
export class OrgChartDetailNodeComponent {
  @Input() visible: boolean = false;
  @Input() nodeDetail?: UserDetail;
  @Output() visibleChange = new EventEmitter<boolean>();

  readonly translateDetailPrefix = 'user-management.user-detail.';
}
