<input
  #avatarInput
  class="hidden"
  type="file"
  accept="image/gif, image/jpeg, image/png"
  (click)="avatarInput.value = ''"
  (change)="onFileSelect($event)" />

<div class="bg-white min-h-content px-6 relative">
  @if (isLoading) {
    <div class="w-[calc(100%-28px)] h-content bg-neutral-700/10 -mx-6 absolute flex justify-center items-center z-50">
      <app-custom-loading></app-custom-loading>
    </div>
  }
  <div class="max-w-250 mx-auto">
    <h1 class="py-5 text-5 font-semibold">{{ translatePrefix + 'account-settings' | translate }}</h1>

    <div>
      <!-- Profile -->
      <div class="flex gap-6">
        <div class="flex-1">
          <h2 class="text-4 font-medium">{{ translatePrefix + 'profile' | translate }}</h2>
          <p class="text-neutral-600">{{ translatePrefix + 'description-profile' | translate }}</p>
        </div>

        <div class="flex-[2] flex flex-col gap-5">
          <div class="flex flex-col gap-2">
            <div class="font-medium">{{ translatePrefix + 'avatar' | translate }}</div>
            <div class="flex items-end gap-4">
              @if (fileSelectState.avatarPreview) {
                <img
                  class="h-24 w-24 rounded-full object-cover object-center"
                  [src]="fileSelectState.avatarPreview"
                  alt="Avatar" />
              } @else {
                <app-custom-avatar
                  size="2xl"
                  [name]="userInfo.fullName"
                  [imgId]="userInfo?.avatar ?? ''"></app-custom-avatar>
              }
              <div class="flex items-center gap-2">
                <app-button
                  size="sm"
                  [label]="translatePrefix + 'upload-image' | translate"
                  variant="outline-secondary"
                  (onClick)="avatarInput.click()"></app-button>
                @if (isChangeAvatar) {
                  <app-button
                    size="sm"
                    label="Remove"
                    variant="outline-secondary"
                    (onClick)="onRemoveAvatar()"></app-button>
                }
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <label class="font-medium">{{ translatePrefix + 'full-name' | translate }}</label>
            <input pInputText class="h-9 w-full" variant="filled" [disabled]="true" [value]="userInfo.fullName" />
          </div>
        </div>
      </div>

      <p-divider styleClass="my-6"></p-divider>

      <!-- Account security -->
      <div class="flex gap-6">
        <div class="flex-1">
          <h2 class="text-4 font-medium">{{ translatePrefix + 'account-security' | translate }}</h2>
          <p class="text-neutral-600">{{ translatePrefix + 'description-account-security' | translate }}</p>
        </div>

        <div class="flex-[2] flex flex-col gap-5">
          <div class="flex flex-col gap-1">
            <label class="font-medium">{{ translatePrefix + 'email' | translate }}</label>
            <input pInputText class="h-9 w-full" variant="filled" [disabled]="true" [value]="userInfo.email" />
          </div>

          <div class="flex flex-col gap-1">
            <label class="font-medium" for="">{{ translatePrefix + 'password' | translate }}</label>
            <app-button
              [label]="translatePrefix + 'change-password' | translate"
              variant="outline-secondary"
              (onClick)="isChangePassword = true"></app-button>
          </div>

          <!-- Two-factor authentication (2FA) -->
          <div class="flex gap-2 items-center">
            <p-inputSwitch [(ngModel)]="this.twoFactorState.isTwoFactor" (onChange)="onChangeTwoFactor($event)" />
            <span>{{ translatePrefix + 'two-factor-authentication' | translate }}</span>
          </div>
        </div>
      </div>

      <p-divider styleClass="my-6"></p-divider>

      <!-- Theme color -->
      <div class="flex gap-6">
        <div class="flex-1">
          <h2 class="text-4 font-medium">{{ translatePrefix + 'theme-color' | translate }}</h2>
          <p class="text-neutral-600">{{ translatePrefix + 'description-theme-color' | translate }}</p>
        </div>

        <div class="flex-[2] flex items-center gap-3">
          @for (COLOR of COLORS_THEME; track $index) {
            <div
              class="flex items-center justify-center w-7 h-7 rounded-sm cursor-pointer transition"
              [ngClass]="COLOR === themeState.theme ? 'ring-2 ring-neutral-300' : ''"
              [style.backgroundColor]="COLOR"
              (click)="onChangeTheme(COLOR)">
              @if (COLOR === themeState.theme) {
                <i class="icon-wl-check text-white"></i>
              }
            </div>
          }
        </div>
      </div>

      <p-divider styleClass="my-6"></p-divider>

      <!-- Language -->
      <div class="flex gap-6">
        <div class="flex-1">
          <h2 class="text-4 font-medium">{{ translatePrefix + 'language' | translate }}</h2>
          <p class="text-neutral-600">{{ translatePrefix + 'choose-your-language' | translate }}</p>
        </div>
        <div class="flex-[2] flex flex-col gap-1">
          <label for="" class="font-medium">{{ translatePrefix + 'language' | translate }}</label>
          <p-dropdown
            styleClass="h-9 w-full"
            [options]="LANGUAGES"
            optionLabel="name"
            optionValue="language"
            dropdownIcon="icon-wl-arrow-drop-down"
            [(ngModel)]="languageState.lang"
            (onChange)="onChangeLanguage()"></p-dropdown>
        </div>
      </div>

      <p-divider styleClass="my-8"></p-divider>

      <div class="flex items-center justify-end gap-3">
        @if (isDirty) {
          <app-button label="Cancel" variant="outline-secondary" size="lg" (onClick)="onCancel()"></app-button>
        }
        <app-button label="Save" size="lg" [disabled]="!isDirty" (onClick)="onSave()"></app-button>
      </div>
    </div>
  </div>
</div>

<!-- CHANGE PASSWORD -->
<p-dialog
  [header]="translatePrefix + 'change-password' | translate"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '480px' }"
  [(visible)]="isChangePassword"
  [focusOnShow]="false">
  <form class="flex flex-col gap-4" [formGroup]="changePasswordForm">
    <!-- HINT -->
    <div class="flex flex-col gap-1">
      <div>{{ translatePrefix + 'use-a-password' | translate }}:</div>
      <!-- MINLENGTH -->
      <div
        class="h-4 flex items-center gap-1"
        [ngClass]="{
          'text-neutral-600': !passwordControl.dirty,
          'text-green-600': passwordControl.dirty && !passwordControl.hasError('minlength'),
          'text-red-600': passwordControl.dirty && passwordControl.hasError('minlength')
        }">
        <i
          class="text-4"
          [ngClass]="{
            'icon-wl-minus': !passwordControl.dirty,
            'icon-wl-check': passwordControl.dirty && !passwordControl.hasError('minlength'),
            'icon-wl-close': passwordControl.dirty && passwordControl.hasError('minlength')
          }"></i>
        <span class="text-3">{{ translatePrefix + 'minimum-characters' | translate }}</span>
      </div>

      <!-- PATTERN -->
      <div
        class="h-4 flex items-center gap-1"
        [ngClass]="{
          'text-neutral-600': !passwordControl.dirty,
          'text-green-600': passwordControl.dirty && !passwordControl.hasError('pattern'),
          'text-red-600': passwordControl.dirty && passwordControl.hasError('pattern')
        }">
        <i
          class="text-4"
          [ngClass]="{
            'icon-wl-minus': !passwordControl.dirty,
            'icon-wl-check': passwordControl.dirty && !passwordControl.hasError('pattern'),
            'icon-wl-close': passwordControl.dirty && passwordControl.hasError('pattern')
          }"></i>
        <span class="text-3">{{ translatePrefix + 'contains-letters-and-numbers' | translate }}</span>
      </div>
    </div>

    <!-- PASSWORD -->
    <app-form-control
      label="Current password"
      [control]="changePasswordForm.controls['currentPassword']"
      [floatLabel]="true"
      [validates]="[
        {
          error: 'required',
          message: messagePrefix + 'password-is-required' | translate
        },
        {
          error: 'currentPassword',
          message: messagePrefix + 'current-password-is-incorrect' | translate
        }
      ]">
      <p-password
        input
        [feedback]="false"
        styleClass="w-full"
        inputStyleClass="w-full"
        formControlName="currentPassword"
        autocomplete="on"
        [toggleMask]="true">
      </p-password>
    </app-form-control>

    <!-- NEW PASSWORD -->
    <app-form-control
      [label]="translatePrefix + 'new-password' | translate"
      [control]="changePasswordForm.controls['password']"
      [floatLabel]="true">
      <p-password
        input
        [feedback]="false"
        styleClass="w-full"
        inputStyleClass="w-full"
        formControlName="password"
        autocomplete="on"
        [toggleMask]="true">
      </p-password>
    </app-form-control>

    <!-- CONFIRM NEW PASSWORD -->
    <app-form-control
      [label]="translatePrefix + 'confirm-new-password' | translate"
      [control]="changePasswordForm.controls['confirmPassword']"
      [floatLabel]="true"
      [validates]="[
        {
          error: 'required',
          message: messagePrefix + 'confirm-password-is-required' | translate
        },
        {
          error: 'passwordMismatch',
          message: messagePrefix + 'password-did-match' | translate
        }
      ]">
      <p-password
        input
        [feedback]="false"
        styleClass="w-full"
        inputStyleClass="w-full"
        formControlName="confirmPassword"
        autocomplete="on"
        [toggleMask]="true">
      </p-password>
    </app-form-control>
  </form>

  <ng-template pTemplate="footer">
    <app-button label="Cancel" variant="outline-secondary" size="xl" (onClick)="onCancelChangePassword()"></app-button>
    <app-button label="Change" size="xl" [disabled]="isInvalidForm" (onClick)="onChangePassword()"></app-button>
  </ng-template>
</p-dialog>
