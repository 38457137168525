import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { LeadNote } from '@core/models/interfaces/lead/lead-notes';
import { NoteFilters, NoteGroup, NoteStatus } from '@core/models/interfaces/note';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';

import { KeyedValue } from '@core/models/interfaces/common';
import { PolicyDetails } from '@core/models/interfaces/lead/lead-policys';
import { NoteService } from '@core/services/note.service';
import { PolicyService } from '@core/services/policies/policy.service';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';
import { LeadNoteItemComponent } from '../lead-note-item/lead-note-item.component';

@Component({
  selector: 'app-note-table',
  standalone: true,
  imports: [CommonModule, TranslateModule, CapitalizePipe, LeadNoteItemComponent],
  providers: [ConfirmationService],
  templateUrl: './note-table.component.html',
  styleUrl: './note-table.component.scss'
})
export class NoteTableComponent implements OnChanges, OnDestroy {
  @Input() leadId: string = '';
  @Input() userId: string = '';
  @Input() totalNotesStatuses: number = 0;
  @Input() noteStatuses: NoteStatus[] = [];
  @Input() dateAddedSort: KeyedValue;
  @Input() group: NoteGroup;
  @Input() filters: NoteFilters;

  @Output() onDeleteNoteLead = new EventEmitter();

  readonly unsubscribe$ = new Subject();

  policies: PolicyDetails[] = [];
  leadNotes: LeadNote[] = [];
  totalNotes: number = 0;
  isLoading = false;
  isExpanded = false;
  isMore: boolean = false;

  constructor(
    private noteService: NoteService,
    private policyService: PolicyService
  ) {}

  ngOnInit(): void {
    this.fetchPolicies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['filters']?.currentValue) {
      this.fetchLeadNotesGroupBy();
    }
  }

  fetchLeadNotesGroupBy() {
    this.isLoading = true;

    const { keyword, statusFilter, datesFilter, addedByFilter } = this.filters;
    const pageSize = this.isMore ? 1000 : 5;
    const statusIds = statusFilter.map(item => item.id);
    const createdBys = addedByFilter.map(item => item.id);
    const dateAddedFrom = datesFilter?.[0] ? moment(datesFilter[0]).startOf('date').toDate().toISOString() : undefined;
    const dateAddedTo = datesFilter?.[1] ? moment(datesFilter[1]).endOf('date').toDate().toISOString() : undefined;

    this.noteService
      .getNotePageByLeadId({
        createdBys: createdBys,
        leadId: this.leadId,
        sorts: `-isPin|${this.dateAddedSort.value}`,
        keyword: keyword,
        statusIds: statusIds,
        createdFrom: dateAddedFrom,
        createdTo: dateAddedTo,
        size: pageSize
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.leadNotes = res.data.content;
          this.totalNotes = res.data.total;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  onDeleteNote() {
    if (this.totalNotes === 1) {
      this.onDeleteNoteLead.emit();
    }
    this.fetchLeadNotesGroupBy();
  }

  onToggleView() {
    if (this.isLoading) {
      return;
    }
    this.isMore = !this.isMore;
    this.fetchLeadNotesGroupBy();
  }

  fetchPolicies() {
    this.policyService
      .getPolicies({
        leadIds: [this.leadId],
        size: 100
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.policies = res?.data?.content;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
