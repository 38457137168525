import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TableModule } from 'primeng/table';
import { MenuItem, MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { ProductDetailsGroupBys } from '@core/constants/report';
import { ProductDetailsReport, ProductDetailsReportParams } from '@core/models/interfaces/report';
import { ReportService } from '@core/services/report.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { downloadFile } from '@shared/utils/dowloadFile.util';
import { ProductItemReportComponent } from './product-item-report/product-item-report.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { APP_PERMISSION } from '@core/enum/permisson';

@Component({
  selector: 'app-product-details-report',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    MenuModule,
    DropdownModule,
    TranslateModule,
    FormsModule,
    NgxPermissionsModule,
    CustomPaginatorComponent,
    ProductItemReportComponent,
    ButtonComponent,
    EmptyComponent,
    CustomLoadingComponent
  ],
  templateUrl: './product-details-report.component.html',
  styleUrl: './product-details-report.component.scss'
})
export class ProductDetailsReportComponent implements OnChanges {
  @Input() isRunning: boolean = false;
  @Input() params: ProductDetailsReportParams;

  @Output() isRunningChange = new EventEmitter<boolean>();

  readonly unsubscribe$ = new Subject();
  readonly translatePrefix = 'report.';
  readonly messagePrefix = 'report.message.';
  readonly Permission = APP_PERMISSION;
  readonly ProductDetailsGroupBys = ProductDetailsGroupBys;
  readonly exportOptions: MenuItem[] = [
    {
      title: 'CSV',
      img: '/assets/images/icons/file-csv.svg',
      command: () => {
        this.onExportReport('CSV');
      }
    },
    {
      title: 'PDF',
      img: '/assets/images/icons/file-pdf.svg',
      command: () => {
        this.onExportReport('PDF');
      }
    }
  ];

  isLoading: boolean = false;
  isExporting: boolean = false;
  isFetched: boolean = false;
  isFilter: boolean = false;
  groupBy: string = 'COMPANY';
  productDetailsReportDate: Date;
  productDetailsReport: ProductDetailsReport[] = [];
  totalReports: number = 0;
  pagination = {
    page: 0,
    size: 10
  };
  options: number[] = [5, 10, 20, 100];

  constructor(
    private reportService: ReportService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.productDetailsReportDate = new Date();
    if (changes?.['params']?.currentValue) {
      this.groupBy = this.params.groupBy;
      this.pagination = {
        size: 10,
        page: 0
      };

      this.fetchProductDetailsReport();
    }
  }

  get productDetailsReportParams() {
    const leadSourceIds = this.params.leadSources?.map(item => item?.key) as string[];
    const statusIds = this.params.statuses?.map(item => item?.key) as string[];

    return {
      ...this.params,
      statusIds,
      leadSourceIds
    };
  }

  fetchProductDetailsReport() {
    const params = this.productDetailsReportParams;

    this.isLoading = true;
    this.reportService
      .getProductDetailsReport({
        ...params,
        groupBy: this.groupBy,
        page: this.pagination.page,
        size: this.pagination.size
      })
      .subscribe({
        next: res => {
          this.productDetailsReport = res.data.content;
          this.totalReports = res.data.total;
          this.isFetched = true;
          this.isLoading = false;

          this.isRunning = false;
          this.isRunningChange.emit(this.isRunning);
        },
        error: () => {
          this.isLoading = false;

          this.isRunning = false;
          this.isRunningChange.emit(this.isRunning);
        }
      });
  }

  onPagination() {
    this.fetchProductDetailsReport();
  }

  onExportReport(type: 'CSV' | 'PDF') {
    this.isExporting = true;
    const params = this.productDetailsReportParams;
    this.reportService.exportProductDetailsReport(type, params).subscribe({
      next: res => {
        downloadFile(res, 'Product Details Report');
        this.isExporting = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(this.messagePrefix + 'export-product-details-report-failed')
        });
      }
    });
  }
}
