<app-custom-dialog
  [draggable]="false"
  size="base"
  [style]="{ 'min-width': '480px' }"
  [(visible)]="visible"
  (visibleChange)="onCancel()"
  [header]="translatePrefix + 'add-link' | translate">
  <form content class="flex flex-col gap-6" [formGroup]="linkForm">
    <div class="flex flex-col gap-2">
      <span>{{ translatePrefix + 'content' | translate }}</span>

      <div class="flex flex-col gap-3">
        <!-- Title -->
        <app-form-control
          [label]="translatePrefix + 'title' | translate"
          [control]="linkForm?.get('title')"
          [floatLabel]="true"
          [required]="true"
          [validates]="[
            {
              error: 'required',
              message: translatePrefix + 'this-field-is-required' | translate
            }
          ]">
          <input input pInputText formControlName="title" class="w-full" />
        </app-form-control>

        <!-- URL -->
        <app-form-control
          [label]="translatePrefix + 'url' | translate"
          [control]="linkForm?.get('url')"
          [floatLabel]="true"
          [required]="true"
          [validates]="[
            {
              error: 'required',
              message: translatePrefix + 'this-field-is-required' | translate
            },
            {
              error: 'pattern',
              message: translatePrefix + 'this-field-not-format' | translate
            }
          ]">
          <input input pInputText formControlName="url" class="w-full" />
        </app-form-control>
      </div>
    </div>
  </form>

  <div class="pt-4" footer>
    <app-button
      [label]="'action.cancel' | translate"
      variant="outline-secondary"
      size="xl"
      (onClick)="onCancel()"></app-button>
    <app-button
      [label]="(linkDetail?.id ? 'action.update' : 'action.create') | translate"
      size="xl"
      (onClick)="onSaveLink()"></app-button>
  </div>
</app-custom-dialog>
