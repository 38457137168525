export enum ViewOrganization {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
  ADD = 'ADD',
  UPDATE = 'UPDATE'
}

export enum NodeLevel {
  ROOT = 'ROOT',
  SAME_LEVEL = 'SAME_LEVEL',
  CHILD_LEVEL = 'CHILD_LEVEL',
  NONE = 'NONE',
  SAME_LEVEL_NEW = 'SAME_LEVEL_NEW',
  CHILD_LEVEL_NEW = 'CHILD_LEVEL_NEW',
  NONE_NEW = 'NONE_NEW',
  UPDATE = 'UPDATE',
  NEW = 'NEW'
}
