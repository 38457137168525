import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { KeyedValue } from '@core/models/interfaces/common';
import { LeadNote } from '@core/models/interfaces/lead/lead-notes';
import { NoteFilters, NoteGroup, NoteStatus } from '@core/models/interfaces/note';
import { UserDetail } from '@core/models/interfaces/user';
import { NoteService } from '@core/services/note.service';
import { UserService } from '@core/services/user.service';
import { NoteManagementPolicyComponent } from '@feature/note/components/note-management-policy/note-management-policy.component';
import { NoteTableComponent } from '@feature/note/components/note-table/note-table.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomCalendarComponent } from '@shared/components/custom-calendar/custom-calendar.component';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { CustomPaginatorComponent } from '@shared/components/custom-paginator/custom-paginator.component';
import { UserMultiSelectDropdownComponent } from '@shared/components/dropdown/user-multi-select-dropdown/user-multi-select-dropdown.component';
import { EmptyComponent } from '@shared/components/empty/empty.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { AppState } from '@state/app.state';
import { selectUserInfo } from '@state/auth/auth.selectors';
import moment from 'moment';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { forkJoin, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-note-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    ButtonComponent,
    TranslateModule,
    MultiSelectModule,
    EmptyComponent,
    SearchInputComponent,
    CustomPaginatorComponent,
    CustomLoadingComponent,
    CustomCalendarComponent,
    UserMultiSelectDropdownComponent,
    NoteTableComponent,
    NoteManagementPolicyComponent
  ],
  templateUrl: './note-management.component.html',
  styleUrl: './note-management.component.scss'
})
export class NoteManagementComponent implements OnInit, OnDestroy {
  readonly translatePrefix = 'note-management.';
  readonly unsubscribe$ = new Subject();

  totalNotes: number = 0;
  notes: LeadNote[] = [];

  totalGroups: number = 0;
  noteGroups: NoteGroup[] = [];

  totalNotesStatuses: number = 0;
  noteStatuses: NoteStatus[] = [];
  totalUsers = 0;
  users: UserDetail[] = [];

  isLoading = false;
  isSort = false;
  isFilter = false;
  dateAddedSort: KeyedValue = { title: 'Newest first', value: '-createdAt' };
  dateAddedOptions: KeyedValue[] = [
    { title: 'Newest first', value: '-createdAt' },
    { title: 'Oldest first', value: 'createdAt' }
  ];
  groupBy = '';
  groupByOptions: KeyedValue[] = [
    { title: 'None', value: '' },
    { title: 'Lead', value: 'LEAD' }
  ];
  pagination = {
    page: 0,
    size: 10
  };
  paginationGroup = {
    page: 0,
    size: 10
  };

  filters: NoteFilters = {
    keyword: '',
    statusFilter: [],
    addedByFilter: [],
    datesFilter: []
  };

  userInfo: UserDetail;

  constructor(
    private noteService: NoteService,
    private userService: UserService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store
      .select(selectUserInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userInfo => {
        if (userInfo) {
          this.userInfo = userInfo;
        }
      });

    forkJoin([this.noteService.getNoteStatuses(), this.userService.getUsers()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: results => {
          const [statusRes, userRes] = results;
          this.noteStatuses = statusRes.data.content;
          this.totalNotesStatuses = statusRes.data.total;
          this.users = userRes.data.content;
          this.totalUsers = userRes.data.total;
        }
      });

    this.fetchLeadNotes();
  }

  get statusSelected() {
    return this.filters.statusFilter.map(status => status.name).join(', ');
  }

  get addedBySelected() {
    return this.filters.addedByFilter.map(user => user.fullName).join(', ');
  }

  fetchLeadNotes() {
    this.isLoading = true;

    const { keyword, statusFilter, datesFilter, addedByFilter } = this.filters;

    const statusIds = statusFilter.map(item => item.id);
    const createdBys = addedByFilter.map(item => item.id);

    const dateAddedFrom = datesFilter?.[0] ? moment(datesFilter[0]).startOf('date').toDate().toISOString() : undefined;
    const dateAddedTo = datesFilter?.[1] ? moment(datesFilter[1]).endOf('date').toDate().toISOString() : undefined;

    this.noteService
      .getLeadNotes({
        sorts: `-isPin|${this.dateAddedSort.value}`,
        keyword: keyword,
        statusIds: statusIds,
        createdBys: createdBys,
        page: this.pagination.page,
        size: this.pagination.size,
        createdFrom: dateAddedFrom,
        createdTo: dateAddedTo
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.notes = res.data.content;
          this.totalNotes = res.data.total;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  fetchNoteGroups() {
    this.isLoading = true;
    this.noteService
      .getLeadNotesGroupBy({
        page: this.paginationGroup.page,
        size: this.paginationGroup.size
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.noteGroups = res.data.content;
          this.totalGroups = res.data.total;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onSearchNote(keyword: string) {
    this.filters = { ...this.filters, keyword };

    this.resetPagination();
    this.fetchLeadNotes();
  }

  onChangeFilters() {
    this.filters = { ...this.filters };

    if (this.groupBy === '') {
      this.resetPagination();
      this.fetchLeadNotes();
    }
  }

  onChangeGroupBy() {
    if (this.groupBy === 'LEAD') {
      this.fetchNoteGroups();
    } else {
      this.fetchLeadNotes();
    }
  }

  resetPagination() {
    this.pagination = {
      page: 0,
      size: 10
    };
  }

  resetPaginationGroup() {
    this.paginationGroup = {
      page: 0,
      size: 10
    };
  }

  onResetFilter() {
    this.filters = {
      keyword: '',
      statusFilter: [],
      addedByFilter: [],
      datesFilter: []
    };

    this.fetchLeadNotes();
  }

  get resetDisabled() {
    return !this.filters.statusFilter.length && !this.filters.addedByFilter.length && !this.filters.datesFilter.length;
  }

  onFetchNotes() {
    this.fetchNoteGroups();
  }

  deleteNoteLead() {
    this.resetPaginationGroup();
    this.fetchNoteGroups();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
