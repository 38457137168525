<div
  class="w-9 h-9 flex items-center justify-center hover:bg-neutral-alpha-200A rounded-md cursor-pointer"
  (click)="op.toggle($event)">
  @if (isUnRead) {
    <i class="icon-wl-notification text-6" pBadge severity="danger"></i>
  } @else {
    <i class="icon-wl-notification text-6"></i>
  }

  <p-overlayPanel #op>
    <div class="flex flex-col gap-[18px] w-[400px] pt-6 pb-5 max-h-[640px] overflow-y-auto">
      <div class="flex justify-between px-5">
        <span class="font-semibold text-4">Notifications</span>
        <span class="font-medium text-primary-600 cursor-pointer" (click)="onMaskAllAsRead()">{{
          translatePrefix + 'mark-all-as-read' | translate
        }}</span>
      </div>
      @for (notification of notifications; track $index) {
        <div class="pt-3">
          <span class="font-medium text-neutral-600 text-2 px-5">{{ notification.date | formatDate }}</span>
          <ul class="list-none p-0 m-0 flex flex-col">
            @for (subNotification of notification.notifications; track $index) {
              <li
                class="flex items-start gap-2 hover:bg-neutral-100 cursor-pointer py-3 pr-5 relative"
                (click)="
                  onNavigate(
                    subNotification.notificationType,
                    subNotification.objectId,
                    subNotification.path,
                    subNotification.id,
                    op
                  )
                "
                (mouseenter)="onMouseEnterLi(subNotification.notificationType, subNotification.objectId, $event)"
                (mouseleave)="onMouseLeaveLi()">
                <div class="flex items-center gap-1.5 pl-1.5" [ngClass]="{ 'pl-4.5': !subNotification.unread }">
                  @if (subNotification.unread) {
                    <div class="w-1.5 h-1.5 rounded-full bg-primary-500"></div>
                  }
                  <app-custom-avatar
                    [imgId]="subNotification.senderUser.avatar"
                    size="sm"
                    [name]="subNotification.senderUser.fullName"></app-custom-avatar>
                </div>
                <div class="flex flex-col">
                  <div class="flex flex-row gap-1">
                    <span class="font-semibold">{{ subNotification.senderUser.fullName }}</span>
                    <span class="text-neutral-600 truncate">{{ subNotification.title }}</span>
                  </div>
                  <span class="text-neutral-600">{{ subNotification.content }}</span>
                </div>

                <div class="flex items-center gap-2 ml-auto text-2 text-neutral-600">
                  <span class="pt-1 w-max">{{ subNotification.createdAt | timeAgo }}</span>
                </div>
              </li>
            }
          </ul>
        </div>
      }
    </div>
  </p-overlayPanel>
  @if (activeSubmenu) {
    <div
      [ngStyle]="{ 'top.px': submenuPosition.y, 'left.px': submenuPosition.x }"
      class="flex flex-col gap-3 absolute bg-white shadow-lg max-h-[400px] min-w-[400px] overflow-y-auto py-4"
      (mouseleave)="onMouseLeaveSubmenu()"
      (mouseenter)="onMouseEnterSubmenu()"
      (click)="hideSubmenu()">
      <div class="font-semibold pl-4">{{ translatePrefix + 'comments' | translate }}</div>
      @if (!isLoading) {
        @for (comment of comments; track $index) {
          <div class="flex flex-col">
            <div class="flex justify-content-end gap-2 px-4 py-2 hover:bg-neutral-100">
              <app-custom-avatar
                [imgId]="comment.createdBy.avatar"
                size="sm"
                [name]="comment.createdBy.fullName"></app-custom-avatar>
              <div class="flex flex-col gap-1.5 flex-1">
                <div class="flex justify-between">
                  <span class="font-semibold">{{ comment.createdBy.fullName }}</span>
                  <span class="text-neutral-600 text-2">{{ comment.createdAt | timeAgo }}</span>
                </div>
                <span class="break-words whitespace-normal max-w-[300px]">{{ comment.content }}</span>
              </div>
            </div>
            @for (item of comment.appends; track $index) {
              <div class="flex justify-content-end gap-2 pl-11 pr-4 py-2 hover:bg-neutral-100">
                <app-custom-avatar
                  [imgId]="item.createdBy.avatar"
                  size="sm"
                  [name]="item.createdBy.fullName"></app-custom-avatar>
                <div class="flex flex-col gap-1.5 flex-1">
                  <div class="flex justify-between justify-content-center">
                    <span class="font-semibold">{{ item.createdBy.fullName }}</span>
                    <span class="text-neutral-600 text-2">{{ item.createdAt | timeAgo }}</span>
                  </div>
                  <span class="break-words whitespace-normal max-w-[300px]">{{ item.content }}</span>
                </div>
              </div>
            }
          </div>
        }
      } @else {
        <app-custom-loading styleClass="h-[30vh] w-full flex justify-center items-center"></app-custom-loading>
      }
    </div>
  }
</div>
