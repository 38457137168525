import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordBody } from '@core/models/interfaces/account-setting';
import { ResponseApi } from '@core/models/interfaces/common';
import { UserDetail } from '@core/models/interfaces/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountSettingService {
  private baseUrl = `${environment.API_URL}/crm/account`;

  constructor(private http: HttpClient) {}

  updateThemeColorUser(theme: string) {
    return this.http.put<ResponseApi<UserDetail>>(`${this.baseUrl}/theme-color`, theme);
  }

  updateLanguageUser(language: string) {
    return this.http.put<ResponseApi<UserDetail>>(`${this.baseUrl}/language`, language);
  }

  changePassword(body: ChangePasswordBody) {
    return this.http.put<ResponseApi<UserDetail>>(`${this.baseUrl}/change-password`, body);
  }

  updateAvatarUser(avatarId: string) {
    return this.http.put<ResponseApi<UserDetail>>(`${this.baseUrl}/avatar`, avatarId);
  }

  removeAvatarUser() {
    return this.http.delete<ResponseApi<UserDetail>>(`${this.baseUrl}/avatar`);
  }

  config2FA(isEnable: boolean) {
    let params = new HttpParams();

    params = params.set('isEnable', isEnable ?? false);

    return this.http.put<ResponseApi<UserDetail>>(`${this.baseUrl}/config2FA`, null, { params });
  }

  verifyPassword(currentPassword: string) {
    return this.http.put<ResponseApi<boolean>>(`${this.baseUrl}/verify-password`, { currentPassword: currentPassword });
  }
}
