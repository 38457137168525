import { Component, Input } from '@angular/core';
import { ChartOption } from '@core/models/interfaces/dashboard';

@Component({
  selector: 'app-doughnut-chart-legend',
  standalone: true,
  imports: [],
  templateUrl: './doughnut-chart-legend.component.html',
  styleUrl: './doughnut-chart-legend.component.scss'
})
export class DoughnutChartLegendComponent {
  @Input() options: ChartOption[] = [];
}
