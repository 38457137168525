@if (isFetched || performanceReport.length) {
  <div class="flex flex-col gap-3">
    <!-- Caption -->
    <div class="flex items-center justify-between">
      <h3 class="font-semibold text-4">
        {{ translatePrefix + 'report' | translate }} {{ performanceReportDate | date: 'MM/dd/yyyy HH:mm' }}
      </h3>

      <div *ngxPermissionsOnly="Permission.REPORT_EXPORT">
        <app-button
          (onClick)="exportMenu.toggle($event)"
          label="Export"
          size="sm"
          icon="icon-wl-upload"
          iconPosition="left"
          [isDropdown]="true"
          [loading]="isExporting"
          variant="outline-secondary"></app-button>
        <p-menu #exportMenu [model]="exportOptions" [popup]="true">
          <ng-template pTemplate="item" let-item>
            <div class="flex items-center gap-2 p-2 cursor-pointer">
              <img class="w-5 h-5" [src]="item.img" [alt]="item.title" />
              <span>{{ item.title }}</span>
            </div>
          </ng-template>
        </p-menu>
      </div>
    </div>

    <!-- Table -->
    <p-table
      [value]="performanceReport"
      [scrollable]="true"
      dataKey="id"
      [lazy]="true"
      (onSort)="onSort($event)"
      [loading]="isLoading"
      [customSort]="true">
      <ng-template pTemplate="header">
        <tr class="truncate">
          <th
            pFrozenColumn
            [frozen]="true"
            alignFrozen="left"
            frozenColStyle="left"
            [pSortableColumn]="PerformanceSortFieldEnum.FULLNAME">
            <div class="flex items-center">
              <div class="w-12">{{ 'report.no.' | translate }}</div>
              <div>
                <app-sort-table-header
                  [label]="translatePrefix + 'user' | translate"
                  [field]="PerformanceSortFieldEnum.FULLNAME"
                  [sortField]="sortField"
                  [sortOrder]="sortOrder"></app-sort-table-header>
              </div>
            </div>
          </th>

          <!-- Notes -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.NOTE">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-notes' | translate"
              [field]="PerformanceSortFieldEnum.NOTE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Click Calls -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.CLICK_CALL">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-click-calls' | translate"
              [field]="PerformanceSortFieldEnum.CLICK_CALL"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Updated Leads -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.UPDATED_LEAD">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-updated-leads' | translate"
              [field]="PerformanceSortFieldEnum.UPDATED_LEAD"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- No Updated Leads -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.NO_UPDATED_LEAD">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-no-updated-leads' | translate"
              [field]="PerformanceSortFieldEnum.NO_UPDATED_LEAD"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Tasks -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.TASK">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-tasks' | translate"
              [field]="PerformanceSortFieldEnum.TASK"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Appointments -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.APPOINTMENT">
            <app-sort-table-header
              [label]="translatePrefix + 'number-of-appointments' | translate"
              [field]="PerformanceSortFieldEnum.APPOINTMENT"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Total leads created -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.LEAD_CREATED">
            <app-sort-table-header
              [label]="translatePrefix + 'total-leads-created' | translate"
              [field]="PerformanceSortFieldEnum.LEAD_CREATED"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Total policies created -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.POLICY_CREATED">
            <app-sort-table-header
              [label]="translatePrefix + 'total-policies-created' | translate"
              [field]="PerformanceSortFieldEnum.POLICY_CREATED"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>

          <!-- Hours online -->
          <th class="!text-right" [pSortableColumn]="PerformanceSortFieldEnum.HOURS_ONLINE">
            <app-sort-table-header
              [label]="translatePrefix + 'hours-online' | translate"
              [field]="PerformanceSortFieldEnum.HOURS_ONLINE"
              [sortField]="sortField"
              [sortOrder]="sortOrder"></app-sort-table-header>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr class="truncate">
          <!-- No. -->
          <td pFrozenColumn [frozen]="true" alignFrozen="left" frozenColStyle="left">
            <div class="flex items-center">
              <div class="w-12">{{ i + 1 }}</div>
              <div>{{ item.fullName }}</div>
            </div>
          </td>

          <!-- Note -->
          <td class="!text-right !pr-7">{{ item.notes }}</td>

          <!-- Click call -->
          <td class="!text-right !pr-7">{{ item.clickCalls }}</td>

          <!-- Updated lead -->
          <td class="!text-right !pr-7">{{ item.updatedLeads }}</td>

          <!-- No updated lead -->
          <td class="!text-right !pr-7">{{ item.noUpdatedLeads }}</td>

          <!-- Task -->
          <td class="!text-right !pr-7">{{ item.tasks }}</td>

          <!-- Appointment -->
          <td class="!text-right !pr-7">{{ item.appointments }}</td>

          <!-- Lead created -->
          <td class="!text-right !pr-7">{{ item.createdLeads }}</td>

          <!-- Policy created -->
          <td class="!text-right !pr-7">{{ item.createdPolicies }}</td>

          <!-- Hour online -->
          <td class="!text-right !pr-7">{{ item.hoursOnline }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="20">
            <app-empty-table></app-empty-table>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <app-custom-paginator
      [(pagination)]="pagination"
      [totalRecords]="totalReports"
      [options]="options"
      (onChange)="onPagination()"></app-custom-paginator>
  </div>
}
