<div class="bg-white min-h-screen overflow-hidden">
  <div class="w-full flex min-h-screen">
    <!-- BACKGROUND -->
    <div class="relative flex-1 h-screen p-4">
      @if (bannerState.data) {
        <app-custom-image
          [imgId]="bannerState.data.attachment"
          [alt]="bannerState.data.location"
          styleClass="w-full h-full"
          imageClass="rounded-md w-full"></app-custom-image>

        <span class="absolute text-7 bottom-12 left-8 text-white font-semibold">
          {{ bannerState.data?.title }}
        </span>
      } @else {
        <img class="w-full h-full object-cover object-top rounded-md" src="/assets/images/BackgroundLogin.svg" alt="" />
        <span class="absolute text-7 bottom-12 left-8 text-white font-semibold"
          >{{ environment.APP_NAME }} - Where talent is created.</span
        >
      }
    </div>

    <div class="relative flex-1 flex justify-center items-center flex-col min-h-screen">
      <div class="h-full w-full flex items-center justify-center">
        <router-outlet></router-outlet>
      </div>
      <span class="absolute right-8 bottom-3 text-neutral-600">
        © 2024 {{ titleState.data?.title }}. All rights reserved.
      </span>
    </div>
  </div>
</div>
