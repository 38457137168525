<div class="flex flex-col gap-6">
  <div class="flex flex-col gap-6">
    <div class="flex gap-6">
      <div class="flex-1 flex border rounded-lg">
        @for (statistic of policyStatisticsByTotalPremium; track $index) {
          <div class="flex-1">
            <app-statistic
              [isBorder]="false"
              [title]="statistic.title"
              [amount]="statistic.amount"
              [dataType]="statistic.dataType"
              [isAll]="statistic.isAll"></app-statistic>
          </div>
        }
      </div>

      <div class="flex-1 flex border rounded-lg">
        @for (statistic of policyStatisticsByTotalIssuedPremium; track $index) {
          <div class="flex-1">
            <app-statistic
              [isBorder]="false"
              [title]="statistic.title"
              [amount]="statistic.amount"
              [dataType]="statistic.dataType"
              [rate]="statistic.rate"
              [isAll]="statistic.isAll"></app-statistic>
          </div>
        }
      </div>
    </div>

    <div class="flex gap-6">
      <div class="flex-1 flex border rounded-lg">
        @for (statistic of policyStatisticsByPremium; track $index) {
          <div class="flex-1">
            <app-statistic
              [isBorder]="false"
              [title]="statistic.title"
              [amount]="statistic.amount"
              [dataType]="statistic.dataType"
              [rate]="statistic.rate"
              [isAll]="statistic.isAll"></app-statistic>
          </div>
        }
      </div>

      <div class="flex-1 flex border rounded-lg">
        @for (statistic of policyStatisticsByIssuedPremium; track $index) {
          <div class="flex-1">
            <app-statistic
              [isBorder]="false"
              [title]="statistic.title"
              [amount]="statistic.amount"
              [dataType]="statistic.dataType"
              [rate]="statistic.rate"
              [isAll]="statistic.isAll"></app-statistic>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-6">
    <!-- Production -->
    <div class="col-span-2 border rounded-md pb-10">
      <div class="px-6 py-5 flex gap-3 items-start justify-between">
        <div class="flex flex-col gap-1">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'production' | translate }}</h2>
          <p class="text-7 font-semibold">{{ totalProduction | currency }}</p>
        </div>

        <p-selectButton
          [options]="productionOptions"
          [(ngModel)]="productionActive"
          (onChange)="fetchProductionChartData()"
          [allowEmpty]="false"
          optionValue="value">
          <ng-template pTemplate="item" let-item>
            <div>
              {{ item.title }}
            </div>
          </ng-template>
        </p-selectButton>
      </div>

      <div class="px-6 pt-2 pb-4">
        <p-chart type="bar" height="336px" [data]="productionChartData" [options]="productionChartOptions"></p-chart>
      </div>
    </div>

    <!-- Production by company -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div class="flex-1">
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'production-by-company' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <div class="w-full max-w-[316px] max-h-[316px] h-full">
            @if (productByCompanyChartData) {
              <sunburst-chart class="w-full h-full" [data]="productByCompanyChartData"></sunburst-chart>
            }
          </div>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="productByCompanyOptions"></app-doughnut-chart-legend>
    </div>

    <!-- Policies by source -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'policies-by-source' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <p-chart
            width="200px"
            height="200px"
            type="doughnut"
            [data]="policiesBySourceChartData"
            [options]="doughnutChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="policiesBySourceOptions"></app-doughnut-chart-legend>
    </div>

    <!-- Total sales by policy status -->
    <div class="col-span-2 border rounded-md pb-5 flex flex-col justify-between">
      <div>
        <div class="px-6 py-5 flex gap-3 items-start justify-between">
          <h2 class="text-4 font-semibold">{{ translatePrefix + 'total-sales-by-policy-status' | translate }}</h2>
        </div>

        <div class="px-6 pt-2 pb-4">
          <p-chart
            type="bar"
            height="320px"
            [data]="salesByStatusChartData"
            [options]="salesByStatusChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="salesByStatusOptions"></app-doughnut-chart-legend>
    </div>

    <!-- Policies by status -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div class="flex-1">
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'policies-by-status' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <div class="w-full max-w-[200px] max-h-[200px] h-full">
            @if (policiesByStatusChartData) {
              <sunburst-chart class="w-full h-full" [data]="policiesByStatusChartData"></sunburst-chart>
            }
          </div>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="policiesByStatusOptions"></app-doughnut-chart-legend>
    </div>

    <!-- Policies by tracking -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div>
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'policies-by-tracking' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <p-chart
            width="200px"
            height="200px"
            type="doughnut"
            [data]="policiesByTrackingChartData"
            [options]="doughnutChartOptions"></p-chart>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="policiesByTrackingOptions"></app-doughnut-chart-legend>
    </div>

    <!-- Policies by company -->
    <div class="flex flex-col justify-between rounded-md border px-6 py-5">
      <div class="flex-1">
        <h2 class="font-semibold text-4 pb-5">{{ translatePrefix + 'policies-by-company' | translate }}</h2>
        <div class="pt-7 pb-13 flex justify-center">
          <div class="w-full max-w-[200px] max-h-[200px] h-full">
            @if (policiesByCompanyChartData) {
              <sunburst-chart class="w-full h-full" [data]="policiesByCompanyChartData"></sunburst-chart>
            }
          </div>
        </div>
      </div>

      <app-doughnut-chart-legend [options]="policiesByCompanyOptions"></app-doughnut-chart-legend>
    </div>
  </div>
</div>
